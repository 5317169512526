import { Space } from '@mantine/core'
import { Outlet } from 'react-router-dom'

export default function ExamsLayout() {
	return (
		<>
			<Space h={24} />
			<Outlet />
		</>
	)
}
