import { showNotification } from '@mantine/notifications'
import { Operation } from 'fast-json-patch'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { examFromDto } from '../mappers/examMappers'
import Exam from '../models/exam'
import ExamService from '../services/examService'
import useUser from './useUser'

const useUpdateExam = (exam: Exam) => {
	const queryClient = useQueryClient()
	const { t } = useTranslation()
	const user = useUser()

	return useMutation(
		async (patchOperations: Operation[]) => {
			const dto = await new ExamService(user?.accessToken).updateAsync(
				exam.id,
				patchOperations
			)
			return examFromDto(dto)
		},
		{
			onSuccess: (newExam) => {
				queryClient.setQueryData(['exams', exam.id], newExam)
				showNotification({
					color: 'green',
					message: t('updateSucceeded'),
				})
			},
			onError: (err) => {
				showNotification({
					color: 'red',
					message: (err as Error).message,
				})
			},
		}
	)
}

export default useUpdateExam
