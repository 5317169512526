import { Center, Loader } from '@mantine/core'
import { useEffect } from 'react'
import userManager from '../../configureUserManager'

export default function Logout() {
	useEffect(() => {
		async function init() {
			await userManager.signoutRedirect()
		}

		init()
	}, [])

	return (
		<Center sx={{ height: '100vh' }}>
			<Loader />
		</Center>
	)
}
