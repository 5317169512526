export default interface PatientCommunicationDto {
	processingStatus: PatientCommunicationProcessingStatus
	createdAt?: string
	updatedAt?: string
	completedAt?: string
}

export enum PatientCommunicationProcessingStatus {
	Pending,
	Processing,
	Retrying,
	Failed,
	Completed,
}
