import i18n from 'i18next'
import Exam from '../models/exam'
import { ExamExportModel } from '../models/examExportModel'

export const examToExportModel = (exam: Exam): ExamExportModel => ({
	[i18n.t('identifier')]: exam.identifier,
	[i18n.t('patientName')]: exam.patient.name,
	[i18n.t('medicalHistory')]: exam.patient.medicalHistory,
	[i18n.t('doctorEmail')]: exam.patient.doctor.email,
})
