import { useState } from 'react'

const useExtendableSelectOptions = (
	defaultOptions: string[],
	currentOption?: string
) => {
	const [options, setOptions] = useState<string[]>(
		currentOption
			? [...new Set([...defaultOptions, currentOption])]
			: defaultOptions
	)

	const onCreate = (newOption: string): void =>
		setOptions((prev) => [...prev, newOption])

	return [options, onCreate] as const
}

export default useExtendableSelectOptions
