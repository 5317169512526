import axios from 'axios'

export const apiClient = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT,
})

export const setApiAuthToken = (accessToken: string): void => {
	apiClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

export const nsClinicalApiClient = axios.create({
	baseURL: process.env.REACT_APP_NSCLINICAL_API_ENDPOINT,
})

export const setNsClinicalApiAuthToken = (accessToken: string): void => {
	apiClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}
