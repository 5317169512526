import { Navigate, Route, Routes } from 'react-router-dom'
import App from './App'
import useUser from './hooks/useUser'
import User from './models/user'
import Login from './pages/authPages/Login'
import LoginCallback from './pages/authPages/LoginCallback'
import LoginSilentCallback from './pages/authPages/LoginSilentCallback'
import Logout from './pages/authPages/Logout'
import LogoutCallback from './pages/authPages/LogoutCallback'
import RestrictedAccess from './pages/authPages/RestrictedAccess'
import Home from './pages/Home'
import PatientRegistration from './pages/patientPages/PatientRegistration'
import Exam from './pages/exams/exam/Exam'
import Exams from './pages/exams/Exams'
import ExamsLayout from './pages/exams/ExamsLayout'
import routes from './routes'
import PatientSuccess from './pages/patientPages/PatientSuccess'
import PatientSelfRegistration from './pages/patientPages/PatientSelfRegistration'
import { PatientPin } from './pages/patientPages/PatientPin'

interface ProtectedRouteProps {
	user?: User | null
	element: JSX.Element
}

function ProtectedRoute(props: ProtectedRouteProps) {
	return props.user ? props.element : <RestrictedAccess />
}

export default function Root() {
	const user = useUser()

	const getAuthRoutes = () => (
		<>
			<Route path={routes.login} element={<Login />} />
			<Route path={routes.loginCallback} element={<LoginCallback />} />
			<Route
				path={routes.loginSilentCallback}
				element={<LoginSilentCallback />}
			/>
			<Route path={routes.logout} element={<Logout />} />
			<Route path={routes.logoutCallback} element={<LogoutCallback />} />
		</>
	)

	const getAdminRoutes = () => (
		<Routes>
			<Route path='/' element={<App />}>
				<Route index element={<Navigate to={routes.exams} />} />
				<Route
					path='exams'
					element={
						<ProtectedRoute user={user} element={<ExamsLayout />} />
					}
				>
					<Route index element={<Exams />} />
					<Route path=':examId' element={<Exam />} />
				</Route>
			</Route>
			{getAuthRoutes()}
		</Routes>
	)

	const getDoctorRoutes = () => (
		<Routes>
			<Route path='/' element={<App />}>
				<Route index element={<Navigate to={routes.exams} />} />
				<Route
					path={routes.exams}
					element={
						<ProtectedRoute user={user} element={<ExamsLayout />} />
					}
				>
					<Route index element={<Exams />} />
					<Route path=':examId' element={<Exam />} />
				</Route>
			</Route>
			{getAuthRoutes()}
		</Routes>
	)

	const getNoUserRoutes = () => (
		<Routes>
			<Route path='/' element={<App />}>
				<Route index element={<Home />} />
				<Route
					path={routes.patientPin}
					element={<PatientPin pin='1234' />}
				/>
				<Route
					path={routes.patientRegistration}
					element={<PatientSelfRegistration />}
				/>
				<Route
					path={`${routes.patientRegistration}/${routes.sub.success}`}
					element={<PatientSuccess />}
				/>
				<Route
					path={`${routes.exams}/:id/${routes.sub.patient}`}
					element={<PatientRegistration />}
				/>
				<Route
					path={`${routes.exams}/:id/${routes.sub.patient}/${routes.sub.success}`}
					element={<PatientSuccess />}
				/>
			</Route>
			{getAuthRoutes()}
		</Routes>
	)

	return !user
		? getNoUserRoutes()
		: user.isAdmin
		? getAdminRoutes()
		: user.isDoctor
		? getDoctorRoutes()
		: getNoUserRoutes()
}
