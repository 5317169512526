import {
	Button,
	Grid,
	Select,
	Stack,
	Textarea,
	Text,
	Group,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { compare, Operation } from 'fast-json-patch'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Calendar, Plus } from 'tabler-icons-react'
import Exam from '../../../../models/exam'
import { defaultFromLabOptions } from './common'
import useUpdateExam from '../../../../hooks/useUpdateExam'
import useExtendableSelectOptions from '../../../../hooks/useExtendableSelectOptions'
import UpdateExamDto, {
	UpdateExamSomaticDto,
} from '../../../../models/api/exam/updateExamDto'

interface SomaticFormProps {
	exam: Exam
}

type FormData = UpdateExamSomaticDto

const getInitialValuesFromExam = (e: Exam): FormData => ({
	sentToNeoscreen: e.somatic?.sentToNeoscreen ?? '',
	sentToNeoscreenAt: e.somatic?.sentToNeoscreenAt?.toISOString(),
	sentToNeoscreenNotes: e.somatic?.sentToNeoscreenNotes ?? '',
	returnedToNeoscreenAt: e.somatic?.returnedToNeoscreenAt?.toISOString(),
	returnedToNeoscreenNotes: e.somatic?.returnedToNeoscreenNotes ?? '',
	returnedToCustomerAt: e.somatic?.returnedToCustomerAt?.toISOString(),
	returnedToCustomerNotes: e.somatic?.returnedToCustomerNotes ?? '',
	sentToLab: e.somatic?.sentToLab ?? '',
	sentToLabAt: e.somatic?.sentToLabAt?.toISOString(),
	sentToLabNotes: e.somatic?.sentToLabNotes ?? '',
	nsClinicalUploadAt: e.somatic?.nsClinicalUploadAt?.toISOString(),
})

export default function SomaticForm(props: SomaticFormProps) {
	const { exam } = props
	const { t, i18n } = useTranslation()

	const [sentToLabOptions, onCreateSentToLabOption] =
		useExtendableSelectOptions(
			defaultFromLabOptions,
			exam.somatic.sentToLab
		)
	const updateMutation = useUpdateExam(exam)
	const form = useForm<FormData>({
		initialValues: getInitialValuesFromExam(exam),
	})

	useEffect(() => {
		form.setValues(getInitialValuesFromExam(exam))
	}, [exam])

	const getPatchOperations = (formValues: FormData): Operation[] => {
		const previous: UpdateExamDto = {
			somatic: { ...getInitialValuesFromExam(exam) },
		}
		const next: UpdateExamDto = {
			somatic: { ...formValues },
		}
		return compare(previous, next)
	}

	const handleSubmit = (formValues: FormData): void => {
		const patchOperations = getPatchOperations(formValues)
		updateMutation.mutate(patchOperations)
	}

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<Grid>
				<Grid.Col span={12}>
					<Grid gutter='xs'>
						<Grid.Col xs={12} md={6}>
							<Select
								label={t('examPage.examAdminTab.sentToLab')}
								data={sentToLabOptions}
								searchable
								creatable
								getCreateLabel={(query) => (
									<Group>
										<Plus size={12} />
										<Text size='sm'>{query}</Text>
									</Group>
								)}
								onCreate={onCreateSentToLabOption}
								{...form.getInputProps('sentToNeoscreen')}
							/>
						</Grid.Col>
						<Grid.Col xs={12} md={6}>
							<DatePicker
								locale={i18n.resolvedLanguage}
								label={t('examPage.examAdminTab.at')}
								placeholder={t(
									'examPage.examAdminTab.pickDate'
								)}
								icon={<Calendar size={16} />}
								error={
									form.getInputProps('sentToNeoscreenAt')
										.error
								}
								value={
									form.getInputProps('sentToNeoscreenAt')
										.value
										? new Date(
												form.getInputProps(
													'sentToNeoscreenAt'
												).value
										  )
										: null
								}
								onChange={(value) =>
									form
										.getInputProps('sentToNeoscreenAt')
										.onChange(value?.toISOString())
								}
							/>
						</Grid.Col>
						<Grid.Col span={12}>
							<Textarea
								placeholder={t('examPage.notes')}
								{...form.getInputProps('sentToNeoscreenNotes')}
							/>
						</Grid.Col>
					</Grid>
				</Grid.Col>
				<Grid.Col span={12}>
					<Stack spacing='xs'>
						<DatePicker
							locale={i18n.resolvedLanguage}
							label={t(
								'examPage.examAdminTab.sampleReturnedToNeoscreenAt'
							)}
							placeholder={t('examPage.examAdminTab.pickDate')}
							icon={<Calendar size={16} />}
							error={
								form.getInputProps('returnedToNeoscreenAt')
									.error
							}
							value={
								form.getInputProps('returnedToNeoscreenAt')
									.value
									? new Date(
											form.getInputProps(
												'returnedToNeoscreenAt'
											).value
									  )
									: null
							}
							onChange={(value) =>
								form
									.getInputProps('returnedToNeoscreenAt')
									.onChange(value?.toISOString())
							}
						/>
						<Textarea
							placeholder={t('examPage.notes')}
							{...form.getInputProps('returnedToNeoscreenNotes')}
						/>
					</Stack>
				</Grid.Col>
				<Grid.Col span={12}>
					<Stack spacing='xs'>
						<DatePicker
							locale={i18n.resolvedLanguage}
							label={t(
								'examPage.examAdminTab.sampleReturnedToCustomerAt'
							)}
							placeholder={t('examPage.examAdminTab.pickDate')}
							icon={<Calendar size={16} />}
							error={
								form.getInputProps('returnedToCustomerAt').error
							}
							value={
								form.getInputProps('returnedToCustomerAt').value
									? new Date(
											form.getInputProps(
												'returnedToCustomerAt'
											).value
									  )
									: null
							}
							onChange={(value) =>
								form
									.getInputProps('returnedToCustomerAt')
									.onChange(value?.toISOString())
							}
						/>
						<Textarea
							placeholder={t('examPage.notes')}
							{...form.getInputProps('returnedToCustomerNotes')}
						/>
					</Stack>
				</Grid.Col>
				<Grid.Col span={12}>
					<Grid gutter='xs'>
						<Grid.Col xs={12} md={6}>
							<Select
								label={t('examPage.examAdminTab.sentToLab')}
								data={sentToLabOptions}
								{...form.getInputProps('sentToLab')}
							/>
						</Grid.Col>
						<Grid.Col xs={12} md={6}>
							<DatePicker
								locale={i18n.resolvedLanguage}
								label={t('examPage.examAdminTab.at')}
								placeholder={t(
									'examPage.examAdminTab.pickDate'
								)}
								icon={<Calendar size={16} />}
								error={form.getInputProps('sentToLabAt').error}
								value={
									form.getInputProps('sentToLabAt').value
										? new Date(
												form.getInputProps(
													'sentToLabAt'
												).value
										  )
										: null
								}
								onChange={(value) =>
									form
										.getInputProps('sentToLabAt')
										.onChange(value?.toISOString())
								}
							/>
						</Grid.Col>
						<Grid.Col span={12}>
							<Textarea
								placeholder={t('examPage.notes')}
								{...form.getInputProps('sentToLabNotes')}
							/>
						</Grid.Col>
					</Grid>
				</Grid.Col>
				<Grid.Col span={12}>
					<DatePicker
						locale={i18n.resolvedLanguage}
						label={t(
							'examPage.examAdminTab.uploadedToNsClinicalAt'
						)}
						placeholder={t('examPage.examAdminTab.pickDate')}
						icon={<Calendar size={16} />}
						error={form.getInputProps('nsClinicalUploadAt').error}
						value={
							form.getInputProps('nsClinicalUploadAt').value
								? new Date(
										form.getInputProps(
											'nsClinicalUploadAt'
										).value
								  )
								: null
						}
						onChange={(value) =>
							form
								.getInputProps('nsClinicalUploadAt')
								.onChange(value?.toISOString())
						}
					/>
				</Grid.Col>
				<Grid.Col>
					<Button
						type='submit'
						loading={updateMutation.isLoading}
						disabled={!getPatchOperations(form.values).length}
					>
						{t('update')}
					</Button>
				</Grid.Col>
			</Grid>
		</form>
	)
}
