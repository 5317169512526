import { SelectItem } from '@mantine/core'
import Finding from '../../../../models/finding'

export const getPrimerSelectData = (childFindings: Finding[]): SelectItem[] => {
	return (
		childFindings?.map<SelectItem>((f) => ({
			value: f.externalId.toString(),
			label: [f.genomicData.geneName, f.genomicData.identifier]
				.filter((x) => x)
				.join(' '),
			genomicData: f.genomicData,
		})) ?? []
	)
}
