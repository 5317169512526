import { Button, Container, PasswordInput, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useLocalStorage } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { pinSuccessStorageKey } from '../../constants'
import routes from '../../routes'

interface PatientPinProps {
	pin: string
}

export function PatientPin(props: PatientPinProps) {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const [, setPinSuccess] = useLocalStorage({
		key: pinSuccessStorageKey,
		defaultValue: false,
	})

	const form = useForm<{ pin: string }>({
		initialValues: { pin: '' },
	})

	useEffect(() => {
		setPinSuccess(false)
	}, [])

	const handleSubmit = (values: { pin: string }) => {
		if (values.pin === props.pin) {
			showNotification({
				message: t('success'),
				color: 'green',
			})
			setPinSuccess(true)
			navigate(routes.patientRegistration)
		} else {
			showNotification({
				message: t('fail'),
				color: 'red',
			})
		}
	}

	return (
		<Container size='xs' pt={200}>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<PasswordInput
						{...form.getInputProps('pin')}
						placeholder={t('patientPage.enterPin')}
						label={t('patientPage.pin')}
					/>
					<Button type='submit'>{t('save')}</Button>
				</Stack>
			</form>
		</Container>
	)
}
