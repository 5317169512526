const routes = {
	root: '/',
	exams: '/exams',
	exam: (id: string) => `/exams/${id}`,
	patientRegistration: '/patient-registration',
	patientPin: '/patient-pin',
	login: '/login',
	loginCallback: '/login-callback',
	loginSilentCallback: '/login-silent-callback',
	logout: '/logout',
	logoutCallback: '/logout-callback',
	sub: {
		patient: 'patient',
		success: 'success',
	},
}

export default routes
