import { Stack, Text, Tooltip } from '@mantine/core'
import { useEffect, useRef, useState } from 'react'

interface TypographyStackProps {
	title: string
	subtitle: string
}

export default function TypographyStack(props: TypographyStackProps) {
	const titleTextRef = useRef<HTMLDivElement | null>(null)

	const [isTitleOverflowing, setIsTitleOverflowing] = useState<boolean>(false)

	useEffect(() => {
		setIsTitleOverflowing(
			titleTextRef.current
				? titleTextRef.current.offsetWidth <
						titleTextRef.current.scrollWidth
				: false
		)
	}, [titleTextRef])

	const getCore = () => (
		<Stack spacing={0}>
			<Text
				ref={titleTextRef}
				weight={600}
				sx={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
				}}
			>
				{props.title}
			</Text>
			<Text size='sm' color='dimmed'>
				{props.subtitle}
			</Text>
		</Stack>
	)

	return isTitleOverflowing ? (
		<Tooltip
			label={props.title}
			wrapLines
			width={220}
			withArrow
			transition='pop'
		>
			{getCore()}
		</Tooltip>
	) : (
		getCore()
	)
}
