import ExamDto from '../models/api/exam/examDto'
import Exam from '../models/exam'

export const examFromDto = (examDto: ExamDto): Exam =>
	examDto && {
		id: examDto.id,
		identifier: examDto.identifier ?? '',
		fatherExamId: examDto.fatherExamId ?? '',
		motherExamId: examDto.motherExamId ?? '',
		relatedExamIds: examDto.relatedExamIds ?? [],
		patient: {
			...examDto.patient,
			email: examDto.patient.email ?? '',
			phone: examDto.patient.phone ?? '',
			fatherName: examDto.patient.fatherName ?? '',
			motherName: examDto.patient.motherName ?? '',
			dateOfBirth: new Date(examDto.patient.dateOfBirth),
			gender: examDto.patient.gender ?? 'male',
			socialSecurityNumber: examDto.patient.socialSecurityNumber ?? '',
			doctor: {
				name: examDto.patient.doctor?.name ?? '',
				email: examDto.patient.doctor?.email ?? '',
				phone: examDto.patient.doctor?.phone ?? '',
			},
			examReason: examDto.patient.examReason ?? '',
			medicalHistory: examDto.patient.medicalHistory ?? '',
			updatedAt: new Date(examDto.patient.updatedAt),
		},
		patientCommunication: examDto.patientCommunication && {
			processingStatus: examDto.patientCommunication.processingStatus,
			createdAt: examDto.patientCommunication.createdAt
				? new Date(examDto.patientCommunication.createdAt)
				: undefined,
			updatedAt: examDto.patientCommunication.updatedAt
				? new Date(examDto.patientCommunication.updatedAt)
				: undefined,
			completedAt: examDto.patientCommunication.completedAt
				? new Date(examDto.patientCommunication.completedAt)
				: undefined,
		},
		sample: examDto.sample && {
			bloodSampleOrigin: examDto.sample.bloodSampleOrigin,
			electronicPrescriptionCode:
				examDto.sample.electronicPrescriptionCode,
			oncologySampleOrigin: examDto.sample.oncologySampleOrigin,
			parafineCudeCode: examDto.sample.parafineCudeCode,
			parafineCudeOrigin: examDto.sample.parafineCudeOrigin,
			sampleType: examDto.sample.sampleType,
			tests: examDto.sample.tests,
			samplePickupDate: examDto.sample.samplePickupDate
				? new Date(examDto.sample.samplePickupDate)
				: undefined,
		},
		genomic: examDto.genomic && {
			sentToLab: examDto.genomic.sentToLab,
			sentToLabAt: examDto.genomic.sentToLabAt
				? new Date(examDto.genomic.sentToLabAt)
				: undefined,
			sentToLabNotes: examDto.genomic.sentToLabNotes,
			rawDataPickupAt: examDto.genomic.rawDataPickupAt
				? new Date(examDto.genomic.rawDataPickupAt)
				: undefined,
			nsClinicalUploadAt: examDto.genomic.nsClinicalUploadAt
				? new Date(examDto.genomic.nsClinicalUploadAt)
				: undefined,
		},
		somatic: examDto.somatic && {
			sentToLab: examDto.somatic.sentToLab,
			sentToLabAt: examDto.somatic.sentToLabAt
				? new Date(examDto.somatic.sentToLabAt)
				: undefined,
			sentToLabNotes: examDto.somatic.sentToLabNotes,
			sentToNeoscreen: examDto.somatic.sentToNeoscreen,
			sentToNeoscreenNotes: examDto.somatic.sentToNeoscreenNotes,
			sentToNeoscreenAt: examDto.somatic.sentToNeoscreenAt
				? new Date(examDto.somatic.sentToNeoscreenAt)
				: undefined,
			returnedToCustomerNotes: examDto.somatic.returnedToCustomerNotes,
			returnedToCustomerAt: examDto.somatic.returnedToCustomerAt
				? new Date(examDto.somatic.returnedToCustomerAt)
				: undefined,
			returnedToNeoscreenNotes: examDto.somatic.returnedToNeoscreenNotes,
			returnedToNeoscreenAt: examDto.somatic.returnedToNeoscreenAt
				? new Date(examDto.somatic.returnedToNeoscreenAt)
				: undefined,
			nsClinicalUploadAt: examDto.somatic.nsClinicalUploadAt
				? new Date(examDto.somatic.nsClinicalUploadAt)
				: undefined,
		},
		findings:
			examDto.findings?.map((f) => ({
				externalId: f.externalId,
				notes: f.notes ?? '',
				doctorApproved: f.doctorApproved,
				doctorApprovedAt: f.doctorApprovedAt
					? new Date(f.doctorApprovedAt)
					: undefined,
				genomicData: { ...f.genomicData },
				isPrimerOrder: f.isPrimerOrder,
			})) ?? [],
		primersOrderedAt: examDto.primersOrderedAt
			? new Date(examDto.primersOrderedAt)
			: undefined,
		createdAt: new Date(examDto.createdAt),
		updatedAt: new Date(examDto.updatedAt),
	}
