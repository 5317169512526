import { Center, Text } from '@mantine/core'

export default function RestrictedAccess() {
	return (
		<Center sx={{ height: '100%' }}>
			<Text>Restricted Access</Text>
			<Text>Please login to access this page.</Text>
		</Center>
	)
}
