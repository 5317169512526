import {
	ActionIcon,
	Button,
	Center,
	CloseButton,
	Group,
	Loader,
	Pagination,
	SimpleGrid,
	Stack,
	Text,
	TextInput,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Download, UserPlus } from 'tabler-icons-react'
import EmptyState from '../../components/EmptyState'
import useExams from '../../hooks/useExams'
import useUser from '../../hooks/useUser'
import Exam from '../../models/exam'
import routes from '../../routes'
import CreateExamModal from './CreateExamModal'
import ExamCard from './ExamCard'
import DeleteExamModal from './DeleteExamModal'
import * as XLSX from 'xlsx'
import { examToExportModel } from '../../mappers/examExportModelMapper'
import ExamService from '../../services/examService'
import { examFromDto } from '../../mappers/examMappers'
import { ExamExportModel } from '../../models/examExportModel'
import { showNotification } from '@mantine/notifications'

export default function Exams() {
	const navigate = useNavigate()
	const [params] = useSearchParams()
	const identifierSearchParam = params.get('identifier')
	const user = useUser()
	const { t } = useTranslation()
	const [searchTerm, setSearchTerm] = useState<string>('')
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500)
	const [page, setPage] = useState<number>(1)
	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
	const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
	const [examToDelete, setExamToDelete] = useState<Exam | undefined>(
		undefined
	)
	const pageSize = 4

	const examsQuery = useExams(page, pageSize, debouncedSearchTerm)

	useEffect(() => {
		if (!identifierSearchParam) {
			return
		}

		new ExamService(user?.accessToken)
			.getByIdentifierAsync(identifierSearchParam)
			.then((r) => navigate(routes.exam(r[0]?.id ?? '')))
			.catch(() =>
				showNotification({
					message: `Exam with identifier ${identifierSearchParam} was not found.`,
					color: 'red',
				})
			)
	}, [])

	useEffect(() => {
		setPage(1)
	}, [searchTerm])

	if (identifierSearchParam || examsQuery.isLoading) {
		return (
			<Center sx={{ height: '100%' }}>
				<Loader />
			</Center>
		)
	}

	if (!examsQuery.data) {
		return (
			<Center sx={{ height: '100%' }}>
				<EmptyState
					title={t('notFoundTitle')}
					message={t('examsPage.noExams')}
					buttonText={t('create')}
					onClick={() => setCreateModalOpen(true)}
				/>
			</Center>
		)
	}

	const onExcelExport = async () => {
		try {
			let exportData: ExamExportModel[] = []
			const examService = new ExamService(user?.accessToken)
			const pageSize = 10
			const lastPage = Math.ceil(examsQuery.data.total / pageSize)
			for (let page = 0; page < lastPage; page++) {
				const nextPageResponse = await examService.getAsync({
					searchTerm,
					page,
					pageSize,
				})
				exportData = [
					...exportData,
					...nextPageResponse.data
						.map(examFromDto)
						.map(examToExportModel),
				]
			}

			const wb = XLSX.utils.book_new()
			const ws = XLSX.utils.json_to_sheet(exportData)
			XLSX.utils.book_append_sheet(wb, ws, 'Exams')
			XLSX.writeFile(wb, 'out.xlsx')
		} catch (e) {
			showNotification({
				message: (e as Error).message,
				color: 'red',
			})
		}
	}

	return (
		<Stack>
			<Group
				position='apart'
				sx={(theme) => ({
					padding: 48,
					borderRadius: 8,
					background: `linear-gradient(to bottom, ${theme.colors.gray[2]}, ${theme.white})`,
				})}
			>
				<Stack spacing={1}>
					<Text size='xs' color='dimmed'>
						{t('overview')}
					</Text>
					<Text size='xl' weight={700} color='gray'>
						{t('exams')}
					</Text>
				</Stack>
				{user?.isAdmin && (
					<Group spacing='xs'>
						<Button
							leftIcon={<UserPlus size={16} />}
							onClick={() => setCreateModalOpen(true)}
						>
							{t('create')}
						</Button>
						<ActionIcon
							variant='outline'
							color='blue'
							size='lg'
							onClick={() => onExcelExport()}
						>
							<Download size={16} />
						</ActionIcon>
					</Group>
				)}
				<CreateExamModal
					open={createModalOpen}
					onClose={() => setCreateModalOpen(false)}
				/>
			</Group>
			<TextInput
				my='xl'
				label=''
				placeholder={t('searchPlaceholder')}
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				rightSection={<CloseButton onClick={() => setSearchTerm('')} />}
			/>
			<Pagination
				page={page}
				onChange={setPage}
				total={Math.ceil(
					examsQuery.data.total / examsQuery.data.pageSize
				)}
				grow
			/>
			<SimpleGrid spacing='md'>
				{examsQuery.data.data.map((e) => (
					<ExamCard
						key={e.id}
						exam={e}
						onView={(id) => navigate(routes.exam(id.toString()))}
						onDelete={(exam) => {
							setExamToDelete(exam)
							setDeleteModalOpen(true)
						}}
					/>
				))}
			</SimpleGrid>
			{user?.isAdmin && (
				<DeleteExamModal
					open={deleteModalOpen}
					onClose={() => {
						setExamToDelete(undefined)
						setDeleteModalOpen(false)
					}}
					exam={examToDelete}
				/>
			)}
		</Stack>
	)
}
