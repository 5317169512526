import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import Root from './Root'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import queryClient from './queryClient'
import { MantineProvider } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import './i18n/config'
import { BrowserRouter } from 'react-router-dom'
import updateLocale from 'dayjs/plugin/updateLocale'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
import { getI18n } from 'react-i18next'

dayjs.extend(updateLocale)
dayjs.extend(LocalizedFormat)
dayjs.locale('el')

getI18n().on('languageChanged', (newLocale) => {
	dayjs.locale(newLocale)
})

ReactDOM.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<MantineProvider withNormalizeCSS withGlobalStyles>
				<NotificationsProvider>
					<BrowserRouter>
						<Root />
					</BrowserRouter>
				</NotificationsProvider>
			</MantineProvider>
			<ReactQueryDevtools initialIsOpen={true} />
		</QueryClientProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
