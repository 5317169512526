import { Button, Group, Modal, Stack, Text, TextInput } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { Trash } from 'tabler-icons-react'
import useUser from '../../hooks/useUser'
import Exam from '../../models/exam'
import ExamService from '../../services/examService'

interface DeleteExamModalProps {
	open: boolean
	onClose: () => void
	exam?: Exam
}

// TODO Change PIN
const deletePin = '1234'

export default function DeleteExamModal(props: DeleteExamModalProps) {
	const { open, onClose, exam } = props

	const { t } = useTranslation()
	const user = useUser()
	const [pin, setPin] = useState<string>('')
	const queryClient = useQueryClient()
	const deleteMutation = useMutation(
		(examId: string) =>
			new ExamService(user?.accessToken).deleteAsync(examId),
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(['exams'])
				showNotification({
					message: t('success'),
					color: 'green',
				})
			},
			onError: () => {
				showNotification({
					message: t('fail'),
					color: 'red',
				})
			},
		}
	)

	useEffect(() => {
		setPin('')
	}, [open])

	const onDelete = () => {
		if (exam && pin === deletePin) {
			deleteMutation.mutate(exam.id, {
				onSuccess: () => {
					onClose()
				},
			})
		}
	}

	return (
		<Modal
			title={t('deleteExamMessage')}
			opened={open}
			onClose={onClose}
			centered
		>
			<Stack>
				<Stack spacing={0}>
					<Text>
						{t('patientName')}: {exam?.patient.name ?? '-'}
					</Text>
					<Text>
						{t('identifier')}: {exam?.identifier ?? '-'}
					</Text>
				</Stack>
				<TextInput
					value={pin}
					onChange={(e) => setPin(e.target.value)}
					placeholder='PIN'
				/>
				<Group>
					<Button variant='outline' onClick={onClose}>
						{t('cancel')}
					</Button>
					<Button
						color='red'
						onClick={onDelete}
						disabled={pin != deletePin}
						loading={deleteMutation.isLoading}
						leftIcon={<Trash size={16} />}
					>
						{t('delete')}
					</Button>
				</Group>
			</Stack>
		</Modal>
	)
}
