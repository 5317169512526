import {
	ActionIcon,
	Avatar,
	Button,
	Container,
	Group,
	Header,
	Menu,
	Text,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Home, Logout } from 'tabler-icons-react'
import useUser from '../hooks/useUser'
import { languages } from '../i18n/config'
import User from '../models/user'
import routes from '../routes'

interface AppBarProps {
	title?: string
}

export default function AppBar(props: AppBarProps) {
	const { title } = props
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()
	const user = useUser()

	const getUserMenu = (user: User | null) =>
		user ? (
			<Menu
				control={
					<Avatar color='blue' radius='xl'>
						{user.name
							.split(' ')
							.map((s) => s.charAt(0).toUpperCase())}
					</Avatar>
				}
				closeOnItemClick={false}
				trigger='hover'
				transition='rotate-left'
				transitionDuration={100}
				transitionTimingFunction='ease'
			>
				<Menu.Label>{t('language')}</Menu.Label>
				<Menu.Item
					disabled={i18n.language === languages.en}
					color='blue'
					onClick={() => i18n.changeLanguage(languages.en)}
				>
					English
				</Menu.Item>
				<Menu.Item
					disabled={i18n.language === languages.el}
					color='blue'
					onClick={() => i18n.changeLanguage(languages.el)}
				>
					Ελληνικά
				</Menu.Item>
				<Menu.Item
					icon={<Logout size={16} />}
					color='red'
					onClick={() => navigate(routes.logout)}
				>
					{t('logout')}
				</Menu.Item>
			</Menu>
		) : (
			<Button onClick={() => navigate(routes.login)}>{t('login')}</Button>
		)

	return (
		<Header height={60} p='xs'>
			<Container>
				<Group position='apart'>
					<Text
						size='xl'
						weight={900}
						color='dark'
						className='cursor-pointer'
						onClick={() => navigate(routes.root)}
					>
						{title ?? 'nspatient'}
					</Text>

					<ActionIcon onClick={() => navigate(routes.root)}>
						<Home />
					</ActionIcon>

					<Group>{getUserMenu(user)}</Group>
				</Group>
			</Container>
		</Header>
	)
}
