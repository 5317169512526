import { useQuery } from 'react-query'
import userManager from '../configureUserManager'
import User from '../models/user'

export default function useUser(): User | null {
	const userQuery = useQuery(['user'], () => userManager.getUser(), {
		onError: () => {
			userManager.signinSilent()
		},
	})

	return userQuery.data
		? {
				id: userQuery.data.profile.sub,
				name: userQuery.data.profile.name ?? '',
				email: userQuery.data.profile.email ?? '',
				username: userQuery.data.profile.username,
				isAdmin: userQuery.data.profile['role'] === 'admin',
				isDoctor: userQuery.data.profile['role'] === 'doctor',
				isPatient: userQuery.data.profile['role'] === 'patient',
				accessToken: userQuery.data.access_token,
		  }
		: null
}
