import { Center, Stack, Text, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export default function PatientSuccess() {
	const { t } = useTranslation()

	return (
		<Center sx={{ height: '100%' }}>
			<Stack>
				<Title>{t('patientPage.informationUpdatedSuccess')}</Title>
				<Text>{t('patientPage.informationUpdatedSuccessDesc')}</Text>
			</Stack>
		</Center>
	)
}
