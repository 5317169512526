import { UserManagerSettings, WebStorageStateStore } from 'oidc-client'

const OidcConfig: UserManagerSettings = {
	authority: process.env.REACT_APP_OIDC_AUTHORITY,
	client_id: 'nspatient-app',
	scope: 'openid profile nspatient-api.all genome-api.all',
	redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
	post_logout_redirect_uri:
		process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI,
	silent_redirect_uri: process.env.REACT_APP_OIDC_SILENT_REDIRECT_URI,
	response_type: 'id_token token',
	automaticSilentRenew: true,
	userStore: new WebStorageStateStore({ store: localStorage }),
}

export default OidcConfig
