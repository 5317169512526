import { useQuery } from 'react-query'
import { listFiles } from '../services/azureBlobService'

const useBlobStorage = (examId?: string) => {
	return useQuery(
		['exams', examId, 'files'],
		() => {
			if (examId) return listFiles(examId)
			else return []
		},
		{
			enabled: !!examId,
		}
	)
}

export default useBlobStorage
