import {
	Alert,
	Box,
	Button,
	Group,
	MultiSelect,
	ScrollArea,
	Skeleton,
	Stack,
	Text,
	Textarea,
	Title,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { compare, Operation } from 'fast-json-patch'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Calendar } from 'tabler-icons-react'
import FileManager from '../../../../components/FileManager'
import useExam from '../../../../hooks/useExam'
import useUpdateExam from '../../../../hooks/useUpdateExam'
import UpdateExamDto, {
	UpdateExamSampleDto,
} from '../../../../models/api/exam/updateExamDto'
import Exam from '../../../../models/exam'
import Finding from '../../../../models/finding'
import FindingCard from './FindingCard'
import { getPrimerSelectData } from './helpers'

type FormData = Pick<
	UpdateExamDto,
	'primerOrderFindingIds' | 'primersOrderedAt'
> &
	Pick<UpdateExamSampleDto, 'tests'>

const getInitialFormValuesFromExam = (e: Exam): FormData => ({
	primerOrderFindingIds:
		e.findings
			?.filter((f) => f.isPrimerOrder)
			.map((f) => f.externalId.toString()) ?? [],
	primersOrderedAt: e.primersOrderedAt?.toString(),
	tests: e.patient.medicalHistory,
})

interface ExamAnalysisProps {
	exam: Exam
}

export default function ExamAnalysis(props: ExamAnalysisProps) {
	const { exam } = props

	const { t, i18n } = useTranslation()
	const fatherExamQuery = useExam(exam.fatherExamId)
	const motherExamQuery = useExam(exam.motherExamId)
	const updateMutation = useUpdateExam(exam)

	const form = useForm<FormData>({
		initialValues: getInitialFormValuesFromExam(exam),
	})

	useEffect(() => {
		form.setValues(getInitialFormValuesFromExam(exam))
	}, [exam])

	if (!exam.identifier) {
		return (
			<Alert color='red'>
				<Text>{t('examPage.examAnalysisTab.noIdentifierMessage')}</Text>
			</Alert>
		)
	}

	const getFindingsTrack = (
		title: string,
		examId: string,
		findings: Finding[],
		loading?: boolean
	) => (
		<Stack spacing={6}>
			<Text transform='uppercase' color='dimmed' size='sm'>
				{title}
			</Text>

			<ScrollArea scrollbarSize={10} pb='md'>
				{loading ? (
					<Group noWrap>
						{[...Array(5).keys()].map((i) => (
							<Skeleton
								key={i}
								visible
								height={100}
								width={200}
							/>
						))}
					</Group>
				) : findings?.length ? (
					<Group noWrap align='stretch'>
						{[...findings].map((finding) => (
							<FindingCard
								key={finding.externalId}
								examId={examId}
								finding={finding}
							/>
						))}
					</Group>
				) : (
					<Text color='gray' size='sm'>
						{t('examPage.examAnalysisTab.noFindings')}
					</Text>
				)}
			</ScrollArea>
		</Stack>
	)

	const getPatchOperations = (formValues: FormData): Operation[] =>
		compare(getInitialFormValuesFromExam(exam), formValues)

	const handleSubmit = (formValues: FormData): void => {
		const patchOperations = getPatchOperations(formValues)
		updateMutation.mutate(patchOperations)
	}

	return (
		<Stack>
			<Stack spacing='xl'>
				<Title order={2}>Family Trio</Title>
				{getFindingsTrack(t('child'), exam.id, exam.findings)}
				{getFindingsTrack(
					fatherExamQuery.data
						? `${t('father')} - ${
								fatherExamQuery.data?.patient.name
						  } - ${fatherExamQuery.data?.identifier}`
						: t('father'),
					exam.fatherExamId,
					fatherExamQuery.isSuccess
						? fatherExamQuery.data.findings
						: [],
					fatherExamQuery.isLoading
				)}
				{getFindingsTrack(
					motherExamQuery.data
						? `${t('mother')} - ${
								motherExamQuery.data?.patient.name
						  } - ${motherExamQuery.data?.identifier}`
						: t('mother'),
					exam.motherExamId,
					motherExamQuery.isSuccess
						? motherExamQuery.data.findings
						: [],
					motherExamQuery.isLoading
				)}
			</Stack>

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack spacing='xl'>
					<Title order={2}>{t('medicalHistory')}</Title>

					<Textarea
						label={t('medicalHistory')}
						defaultValue={exam.patient.medicalHistory}
					/>

					<Textarea
						label={t('reason')}
						defaultValue={exam.patient.examReason}
					/>

					<MultiSelect
						{...form.getInputProps('primerOrderFindingIds')}
						label={t('examPage.examAnalysisTab.primers')}
						data={getPrimerSelectData(exam.findings)}
					/>

					<DatePicker
						locale={i18n.resolvedLanguage}
						label={t('examPage.examAnalysisTab.primersOrderedAt')}
						icon={<Calendar size={16} />}
						error={form.getInputProps('primersOrderedAt').error}
						value={
							form.getInputProps('primersOrderedAt').value
								? new Date(
										form.getInputProps(
											'primersOrderedAt'
										).value
								  )
								: null
						}
						onChange={(value) =>
							form
								.getInputProps('primersOrderedAt')
								.onChange(value?.toISOString())
						}
					/>

					<FileManager examId={exam.id} />

					<Box>
						<Button
							type='submit'
							loading={updateMutation.isLoading}
							disabled={!getPatchOperations(form.values).length}
						>
							{t('update')}
						</Button>
					</Box>
				</Stack>
			</form>
		</Stack>
	)
}
