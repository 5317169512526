import { Button, Stack, Text, Title } from '@mantine/core'

interface EmptyStateProps {
	title: string
	message: string
	buttonText?: string
	onClick?: () => void
}

export default function EmptyState(props: EmptyStateProps) {
	const { title, message, buttonText, onClick } = props

	return (
		<Stack spacing='sm'>
			<Title order={3} color='gray'>
				{title}
			</Title>
			<Text color='gray'>{message}</Text>
			{onClick && <Button onClick={onClick}>{buttonText}</Button>}
		</Stack>
	)
}
