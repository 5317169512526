import { AppShell, Container } from '@mantine/core'
import { Outlet } from 'react-router-dom'
import AppBar from './components/AppBar'
import Footer from './components/Footer'

function App() {
	return (
		<AppShell fixed header={<AppBar />} footer={<Footer />}>
			<Container>
				<Outlet />
			</Container>
		</AppShell>
	)
}

export default App
