import { Center, Loader } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import userManager from '../../configureUserManager'
import routes from '../../routes'

export default function LoginCallback() {
	const navigate = useNavigate()

	useEffect(() => {
		async function init() {
			try {
				await userManager.signinRedirectCallback()
			} catch (e) {
				showNotification({
					message: (e as Error)?.message ?? 'Something went wrong.',
				})
			} finally {
				navigate(routes.root)
			}
		}

		init()
	}, [])

	return (
		<Center sx={{ height: '100vh' }}>
			<Loader />
		</Center>
	)
}
