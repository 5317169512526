import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import EmptyState from '../../../components/EmptyState'
import useUser from '../../../hooks/useUser'
import { Button, Alert, Center, Loader, Stack, Tabs, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useTranslation } from 'react-i18next'
import ExamService from '../../../services/examService'
import ExamAdmin from './admin/ExamAdmin'
import ExamAnalysis from './analysis/ExamAnalysis'
import { AxiosError } from 'axios'
import { isNotFoundError } from '../../../helpers/errorHelper'
import { AlertCircle } from 'tabler-icons-react'
import useExam from '../../../hooks/useExam'

export default function Exam() {
	const { examId = '' } = useParams()
	const user = useUser()
	const { t } = useTranslation()

	const queryClient = useQueryClient()

	const examQuery = useExam(examId)

	useQuery(
		['exams', examId, 'sync-findings'],
		() => new ExamService(user?.accessToken).syncFindingsAsync(examId),
		{
			enabled: !!examId && !!examQuery.data?.identifier,
			onError: () => {
				showNotification({
					color: 'red',
					message: t('examPage.findingSyncFailed'),
				})
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['exams', examId], {
					exact: true,
				})
			},
		}
	)

	if (examQuery.isLoading) {
		return (
			<Center sx={{ height: '100%' }}>
				<Loader />
			</Center>
		)
	}

	if (examQuery.isError) {
		return (
			<Center sx={{ height: '100%' }}>
				<Alert
					icon={<AlertCircle size={16} />}
					color='red'
					title='Oops'
				>
					<Stack align='flex-start'>
						<Text>
							{isNotFoundError(examQuery.error as AxiosError)
								? 'We could not find what you are looking for.'
								: 'Having trouble at the moment...'}
						</Text>
						<Button
							variant='outline'
							color='red'
							onClick={() => examQuery.refetch()}
						>
							Try Again
						</Button>
					</Stack>
				</Alert>
			</Center>
		)
	}

	if (!examQuery.data) {
		return (
			<Center sx={{ height: '100%' }}>
				<EmptyState
					title={t('notFoundTitle')}
					message={t('examPage.notFoundMessage')}
				/>
			</Center>
		)
	}

	return (
		<Stack>
			<Stack
				spacing={3}
				sx={(theme) => ({
					padding: 48,
					borderRadius: 8,
					background: `linear-gradient(to bottom, ${theme.colors.gray[2]}, ${theme.white})`,
				})}
			>
				<Text size='md' color='dark'>
					{`${t('exam')} ${examQuery.data.identifier || '-'}`}
				</Text>
				<Text size='xl' weight={700} color='gray'>
					{examQuery.data.patient.name}
				</Text>
			</Stack>

			<Tabs position='center' tabPadding='xl'>
				{user?.isAdmin && (
					<Tabs.Tab label={t('examPage.tabAdmin')}>
						<ExamAdmin exam={examQuery.data} />
					</Tabs.Tab>
				)}
				<Tabs.Tab label={t('examPage.tabAnalysis')}>
					<ExamAnalysis exam={examQuery.data} />
				</Tabs.Tab>
			</Tabs>
		</Stack>
	)
}
