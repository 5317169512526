import { boolean, InferType, object, string } from 'yup'
import {
	Box,
	Button,
	Checkbox,
	Select,
	SimpleGrid,
	Stack,
	TextInput,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { Calendar } from 'tabler-icons-react'
import { useMutation, useQueryClient } from 'react-query'
import ExamService from '../services/examService'
import { useForm, yupResolver } from '@mantine/form'
import Exam from '../models/exam'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/el'
import CreateExamByUserDto from '../models/api/exam/createExamDto'
import Gender from '../models/gender'
import { useRef } from 'react'
import FileManager, { UploadFunction } from './FileManager'
import { examFromDto } from '../mappers/examMappers'
import useUser from '../hooks/useUser'
import { showNotification } from '@mantine/notifications'
import { compare, Operation } from 'fast-json-patch'
import UpdateExamDto from '../models/api/exam/updateExamDto'

interface PatientInfoFormProps {
	exam?: Exam
	onSuccess?: (exam: Exam) => void
}

export default function PatientInfoForm(props: PatientInfoFormProps) {
	const { exam, onSuccess } = props

	const user = useUser()
	const { t, i18n } = useTranslation()
	const queryClient = useQueryClient()
	const uploadFuncRef = useRef<UploadFunction>()

	const createMutation = useMutation(
		(request: CreateExamByUserDto) => {
			if (!user) return new ExamService().createByUserAsync(request)

			return new ExamService(user.accessToken).createAsync(request)
		},
		{
			onSuccess: async (newExam) => {
				if (uploadFuncRef?.current) {
					await uploadFuncRef.current(newExam.id)
				}
			},
			onError: () => {
				showNotification({
					message: t('fail'),
					color: 'red',
				})
			},
		}
	)

	const updateMutation = useMutation(
		(operations: Operation[]) =>
			new ExamService(user?.accessToken).updateAsync(
				exam?.id ?? '',
				operations
			),
		{
			onSuccess: async (updatedExam) => {
				if (uploadFuncRef?.current) {
					await uploadFuncRef.current(updatedExam.id)
				}

				queryClient.setQueryData(
					['exams', updatedExam.id],
					examFromDto(updatedExam)
				)
			},
			onError: () => {
				showNotification({
					message: t('fail'),
					color: 'red',
				})
			},
		}
	)

	const formValuesSchema = object({
		identifier: string().required(t('required')),
		name: string().required(t('required')),
		motherName: string(),
		fatherName: string(),
		dateOfBirth: string(),
		gender: string().oneOf([Gender.Male, Gender.Female]),
		socialSecurityNumber: string()
			.length(11, t('patientPage.socialSecurityNumberValidationMessage'))
			.required(t('required')),
		address: string().required(t('required')),
		email: string().email().required(t('required')),
		phone: string().required(t('required')),
		doctorName: string(),
		doctorEmail: string().email(),
		doctorPhone: string(),
		examReason: string(),
		medicalHistory: string(),
		gdprAccepted: boolean().required(t('required')),
	})
	interface FormData extends InferType<typeof formValuesSchema> {}

	const getInitialValuesFromExam = (e?: Exam): FormData => ({
		identifier: e?.identifier ?? '',
		name: e?.patient?.name ?? '',
		email: e?.patient?.email ?? '',
		phone: e?.patient?.phone ?? '',
		motherName: e?.patient?.motherName,
		fatherName: e?.patient?.fatherName,
		dateOfBirth: e?.patient?.dateOfBirth?.toISOString(),
		gender: e?.patient.gender as Gender,
		socialSecurityNumber: e?.patient?.socialSecurityNumber ?? '',
		address: e?.patient?.address ?? '',
		doctorName: e?.patient?.doctor.name,
		doctorEmail: e?.patient?.doctor.email,
		doctorPhone: e?.patient?.doctor.phone,
		examReason: e?.patient?.examReason,
		medicalHistory: e?.patient?.medicalHistory,
		gdprAccepted: e?.patient?.gdprAccepted ?? false,
	})

	const form = useForm<FormData>({
		initialValues: getInitialValuesFromExam(exam),
		schema: yupResolver(formValuesSchema),
	})

	const onSubmit = (values: FormData) => {
		if (!exam) {
			createMutation.mutate(
				{
					...values,
					dateOfBirth: values.dateOfBirth
						? new Date(values.dateOfBirth)
						: undefined,
				},
				{
					onSuccess: (newExam) => {
						if (onSuccess) onSuccess(examFromDto(newExam))
					},
				}
			)
		} else {
			const existingValues: UpdateExamDto = {
				patient: {
					...getInitialValuesFromExam(exam),
					doctor: {
						name: values.doctorName,
						email: values.doctorEmail,
						phone: values.doctorPhone,
					},
				},
			}

			const newValues: UpdateExamDto = {
				patient: {
					...values,
					dateOfBirth: values.dateOfBirth,
					doctor: {
						name: values.doctorName,
						email: values.doctorEmail,
						phone: values.doctorPhone,
					},
				},
			}

			const patchOperations: Operation[] = compare(
				existingValues,
				newValues
			)

			updateMutation.mutate(patchOperations, {
				onSuccess: (newExam) => {
					if (onSuccess) onSuccess(examFromDto(newExam))
				},
			})
		}
	}

	return (
		<form onSubmit={form.onSubmit(onSubmit)}>
			<Stack>
				<TextInput
					id='identifier-input'
					required
					{...form.getInputProps('identifier')}
					label={t('identifier')}
				/>

				<TextInput
					id='name-input'
					required
					{...form.getInputProps('name')}
					label={t('name')}
				/>

				<SimpleGrid cols={2}>
					<TextInput
						id='mother-name-input'
						{...form.getInputProps('motherName')}
						label={t('motherName')}
					/>

					<TextInput
						id='father-name-input'
						{...form.getInputProps('fatherName')}
						label={t('fatherName')}
					/>
				</SimpleGrid>

				<TextInput
					id='socialSecurityNumber-input'
					required
					{...form.getInputProps('socialSecurityNumber')}
					label={t('socialSecurityNumber')}
				/>

				<SimpleGrid cols={2}>
					<DatePicker
						id='dob-input'
						locale={i18n.language}
						error={form.getInputProps('dateOfBirth').error}
						value={
							form.getInputProps('dateOfBirth').value
								? new Date(
										form.getInputProps('dateOfBirth').value
								  )
								: null
						}
						onChange={(value) =>
							form
								.getInputProps('dateOfBirth')
								.onChange(value?.toISOString())
						}
						label={t('dateOfBirth')}
						icon={<Calendar size={16} />}
					/>

					<Select
						id='gender-input'
						label={t('gender')}
						{...form.getInputProps('gender')}
						data={[
							{ value: Gender.Male, label: t('male') },
							{ value: Gender.Female, label: t('female') },
						]}
					/>
				</SimpleGrid>

				<TextInput
					id='email-input'
					required
					{...form.getInputProps('email')}
					label={t('email')}
				/>

				<SimpleGrid cols={2}>
					<TextInput
						id='phone-input'
						required
						{...form.getInputProps('phone')}
						label={t('phone')}
					/>

					<TextInput
						id='address-input'
						required
						{...form.getInputProps('address')}
						label={t('address')}
					/>
				</SimpleGrid>

				<TextInput
					id='doctor-name-input'
					{...form.getInputProps('doctorName')}
					label={t('doctorName')}
				/>

				<SimpleGrid cols={2}>
					<TextInput
						id='doctor-email-input'
						{...form.getInputProps('doctorEmail')}
						label={t('doctorEmail')}
					/>

					<TextInput
						id='doctor-phone-input'
						{...form.getInputProps('doctorPhone')}
						label={t('doctorPhone')}
					/>
				</SimpleGrid>

				<TextInput
					id='reason-input'
					{...form.getInputProps('examReason')}
					label={t('reason')}
				/>

				<TextInput
					id='history-input'
					{...form.getInputProps('medicalHistory')}
					label={t('medicalHistory')}
				/>

				<FileManager examId={exam?.id} uploadRef={uploadFuncRef} />

				<Checkbox
					id='gdpr-input'
					label={t('gdpr')}
					{...form.getInputProps('gdprAccepted', {
						type: 'checkbox',
					})}
					required
					disabled={user?.isAdmin}
				/>
				<Box>
					<Button
						type='submit'
						loading={
							createMutation.isLoading || updateMutation.isLoading
						}
					>
						{exam ? t('update') : t('create')}
					</Button>
				</Box>
			</Stack>
		</form>
	)
}
