import { Tabs } from '@mantine/core'
import Exam from '../../../../models/exam'
import { useTranslation } from 'react-i18next'
import GeneralForm from './GeneralForm'
import SampleForm from './SampleForm'
import GenomicForm from './GenomicForm'
import SomaticForm from './SomaticForm'
import PatientForm from './PatientForm'

interface ExamAdminProps {
	exam: Exam
}

export default function ExamAdmin(props: ExamAdminProps) {
	const { exam } = props

	const { t } = useTranslation()

	return (
		<Tabs
			variant='outline'
			position='center'
			tabPadding='xl'
			styles={() => ({
				body: {
					flex: 1,
				},
			})}
		>
			<Tabs.Tab label={t('examPage.examAdminTab.general')}>
				<GeneralForm exam={exam} />
			</Tabs.Tab>
			<Tabs.Tab label={t('examPage.examAdminTab.patient')}>
				<PatientForm exam={exam} />
			</Tabs.Tab>
			<Tabs.Tab label={t('examPage.examAdminTab.sample')}>
				<SampleForm exam={exam} />
			</Tabs.Tab>
			<Tabs.Tab label={t('examPage.examAdminTab.genomic')}>
				<GenomicForm exam={exam} />
			</Tabs.Tab>
			<Tabs.Tab label={t('examPage.examAdminTab.somatic')}>
				<SomaticForm exam={exam} />
			</Tabs.Tab>
		</Tabs>
	)
}
