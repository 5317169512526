import CreateExamDto from '../models/api/exam/createExamDto'
import ExamDto from '../models/api/exam/examDto'
import GetExamsDto from '../models/api/exam/getExamsDto'
import PagedResponse from '../models/api/pagedResponse'
import { apiClient, setApiAuthToken } from './apiClient'
import { Operation } from 'fast-json-patch'
import UpdateFindingDto from '../models/api/exam/updateFindingDto'

export default class ExamService {
	constructor(accessToken?: string) {
		setApiAuthToken(accessToken ?? '')
	}

	createAsync = async (request: CreateExamDto): Promise<ExamDto> => {
		const response = await apiClient.post<ExamDto>('/exams', request)
		return response.data
	}

	createByUserAsync = async (request: CreateExamDto): Promise<ExamDto> => {
		const response = await apiClient.post<ExamDto>(
			'/exams/by-user',
			request
		)
		return response.data
	}

	getAsync = async (
		request: GetExamsDto
	): Promise<PagedResponse<ExamDto[]>> => {
		const response = await apiClient.get<PagedResponse<ExamDto[]>>(
			`/exams`,
			{
				params: request,
			}
		)
		return response.data
	}

	getByIdAsync = async (id: string): Promise<ExamDto> => {
		const response = await apiClient.get<ExamDto>(`/exams/${id}`)
		return response.data
	}

	getByIdentifierAsync = async (
		identifierQuery: string
	): Promise<ExamDto[]> => {
		const response = await apiClient.get<ExamDto[]>(
			`/exams/search/identifier`,
			{
				params: {
					identifierQuery,
				},
			}
		)
		return response.data
	}

	updateAsync = async (
		id: string,
		patchOperations: Operation[]
	): Promise<ExamDto> => {
		const response = await apiClient.patch<ExamDto>(
			`exams/${id}`,
			patchOperations
		)
		return response.data
	}

	syncFindingsAsync = (id: string): Promise<void> => {
		return apiClient.post(`exams/${id}/sync-findings`)
	}

	updateFindingAsync = async (
		examId: string,
		findingId: number,
		request: UpdateFindingDto
	): Promise<ExamDto> => {
		const response = await apiClient.put<ExamDto>(
			`exams/${examId}/findings/${findingId}`,
			request
		)

		return response.data
	}

	deleteAsync = async (id: string): Promise<void> => {
		await apiClient.delete(`exams/${id}`)
	}
}
