import { Center, Loader } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import userManager from '../../configureUserManager'
import routes from '../../routes'

export default function Login() {
	const navigate = useNavigate()

	useEffect(() => {
		const init = async () => {
			try {
				await userManager.signinRedirect()
			} catch (e) {
				navigate(routes.root)
				showNotification({
					message: 'Something went wrong. Please try again.',
				})
			}
		}

		init()
	}, [])

	return (
		<Center sx={{ height: '100vh' }}>
			<Loader />
		</Center>
	)
}
