import { Button, Grid, Group, Select, Textarea, Text } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { compare, Operation } from 'fast-json-patch'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Calendar, Plus } from 'tabler-icons-react'
import useExtendableSelectOptions from '../../../../hooks/useExtendableSelectOptions'
import useUpdateExam from '../../../../hooks/useUpdateExam'
import UpdateExamDto, {
	UpdateExamGenomicDto,
} from '../../../../models/api/exam/updateExamDto'
import Exam from '../../../../models/exam'
import { defaultSentToLabOptions } from './common'

interface GenomicFormProps {
	exam: Exam
}

type FormData = UpdateExamGenomicDto

const getInitialValuesFromExam = (e: Exam): FormData => ({
	sentToLab: e.genomic?.sentToLab ?? '',
	sentToLabAt: e.genomic?.sentToLabAt?.toISOString(),
	sentToLabNotes: e.genomic?.sentToLabNotes ?? '',
	rawDataPickupAt: e.genomic?.rawDataPickupAt?.toISOString(),
	nsClinicalUploadAt: e.genomic?.nsClinicalUploadAt?.toISOString(),
})

export default function GenomicForm(props: GenomicFormProps) {
	const { exam } = props

	const { t, i18n } = useTranslation()
	const [sentToLabOptions, onCreateSentToLabOption] =
		useExtendableSelectOptions(
			defaultSentToLabOptions,
			exam.genomic.sentToLab
		)
	const updateMutation = useUpdateExam(exam)
	const form = useForm<FormData>({
		initialValues: getInitialValuesFromExam(exam),
	})

	useEffect(() => {
		form.setValues(getInitialValuesFromExam(exam))
	}, [exam])

	const getPatchOperations = (formValues: FormData): Operation[] => {
		const previous: UpdateExamDto = {
			genomic: { ...getInitialValuesFromExam(exam) },
		}
		const next: UpdateExamDto = {
			genomic: { ...formValues },
		}
		return compare(previous, next)
	}

	const handleSubmit = async (formValues: FormData): Promise<void> => {
		const patchOperations = getPatchOperations(formValues)
		await updateMutation.mutateAsync(patchOperations)
	}

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<Grid>
				<Grid.Col>
					<Grid gutter='xs'>
						<Grid.Col xs={12} md={6}>
							<Select
								label={t('examPage.examAdminTab.sentToLab')}
								data={sentToLabOptions}
								creatable
								getCreateLabel={(query) => (
									<Group>
										<Plus size={12} />
										<Text size='sm'>{query}</Text>
									</Group>
								)}
								onCreate={onCreateSentToLabOption}
								searchable
								{...form.getInputProps('sentToLab')}
							/>
						</Grid.Col>

						<Grid.Col xs={12} md={6}>
							<DatePicker
								locale={i18n.resolvedLanguage}
								label={t('examPage.examAdminTab.at')}
								placeholder={t(
									'examPage.examAdminTab.pickDate'
								)}
								icon={<Calendar size={16} />}
								error={form.getInputProps('sentToLabAt').error}
								value={
									form.getInputProps('sentToLabAt').value
										? new Date(
												form.getInputProps(
													'sentToLabAt'
												).value
										  )
										: null
								}
								onChange={(value) =>
									form
										.getInputProps('sentToLabAt')
										.onChange(value?.toISOString())
								}
							/>
						</Grid.Col>

						<Grid.Col xs={12}>
							<Textarea
								placeholder={t('examPage.notes')}
								{...form.getInputProps('sentToLabNotes')}
							/>
						</Grid.Col>
					</Grid>
				</Grid.Col>
				<Grid.Col xs={12} md={6}>
					<DatePicker
						locale={i18n.resolvedLanguage}
						label={t('examPage.examAdminTab.rawDataPickupAt')}
						placeholder={t('examPage.examAdminTab.pickDate')}
						icon={<Calendar size={16} />}
						error={form.getInputProps('rawDataPickupAt').error}
						value={
							form.getInputProps('rawDataPickupAt').value
								? new Date(
										form.getInputProps(
											'rawDataPickupAt'
										).value
								  )
								: null
						}
						onChange={(value) =>
							form
								.getInputProps('rawDataPickupAt')
								.onChange(value?.toISOString())
						}
					/>
				</Grid.Col>
				<Grid.Col xs={12} md={6}>
					<DatePicker
						locale={i18n.resolvedLanguage}
						label={t(
							'examPage.examAdminTab.uploadedToNsClinicalAt'
						)}
						placeholder={t('examPage.examAdminTab.pickDate')}
						icon={<Calendar size={16} />}
						error={form.getInputProps('nsClinicalUploadAt').error}
						value={
							form.getInputProps('nsClinicalUploadAt').value
								? new Date(
										form.getInputProps(
											'nsClinicalUploadAt'
										).value
								  )
								: null
						}
						onChange={(value) =>
							form
								.getInputProps('nsClinicalUploadAt')
								.onChange(value?.toISOString())
						}
					/>
				</Grid.Col>
				<Grid.Col>
					<Button
						type='submit'
						loading={updateMutation.isLoading}
						disabled={!getPatchOperations(form.values).length}
					>
						{t('update')}
					</Button>
				</Grid.Col>
			</Grid>
		</form>
	)
}
