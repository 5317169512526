import i18n from 'i18next'
import translations_en from './en/translations.en.json'
import translations_el from './el/translations.el.json'
import { initReactI18next } from 'react-i18next'

export const languages = {
	en: 'en',
	el: 'el',
}

export const resources = {
	[languages.en]: { translation: translations_en },
	[languages.el]: { translation: translations_el },
} as const

i18n.use(initReactI18next).init({
	resources,
	lng: languages.el,
	debug: process.env.NODE_ENV === 'development',
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
})
