import { Center, Paper, Stack, Text, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { User } from 'tabler-icons-react'
import routes from '../routes'

export default function Home() {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<Center sx={{ height: '100%' }}>
			<Stack align='center' spacing={64} sx={{ width: '100%' }}>
				<Title>NsPatient</Title>
				<Paper
					withBorder
					radius='lg'
					shadow='sm'
					sx={(theme) => ({
						width: '20%',
						aspectRatio: `1 / 1`,
						color: theme.white,
						background: theme.colors.blue[6],
						'&:hover': { background: theme.colors.blue[7] },
					})}
					onClick={() => navigate(routes.patientPin)}
				>
					<Center sx={{ height: '100%' }}>
						<Stack align='center'>
							<User size={64} />
							<Text>{t('create')}</Text>
						</Stack>
					</Center>
				</Paper>
			</Stack>
		</Center>
	)
}
