import { BlobServiceClient } from '@azure/storage-blob'

const connString = process.env.REACT_APP_AZURE_STORAGE_CONNECTION_STRING ?? ''

export const uploadFile = async (
	containerName: string,
	file: File,
	onProgress?: (uploadedBytes: number) => void
) => {
	const client = new BlobServiceClient(connString)

	const containerClient = client.getContainerClient(containerName)

	if (!(await containerClient.exists())) {
		await containerClient.create()
	}

	const blobClient = containerClient.getBlockBlobClient(file.name)

	await blobClient.uploadData(file, {
		onProgress: onProgress
			? (e) => {
					onProgress(e.loadedBytes)
			  }
			: undefined,
	})
}

export const uploadFiles = async (
	containerName: string,
	files: File[],
	onStart?: (fileName: string) => void,
	onProgress?: (fileName: string, uploadedBytes: number) => void,
	onSuccess?: (fileName: string) => void,
	onError?: (fileName: string, error: Error) => void
) => {
	const client = new BlobServiceClient(connString)

	const containerClient = client.getContainerClient(containerName)

	if (!(await containerClient.exists())) {
		await containerClient.create()
	}

	const uploadTasks = files.map((file) => {
		const task = async () => {
			try {
				if (onStart) onStart(file.name)

				const blobClient = containerClient.getBlockBlobClient(file.name)

				await blobClient.uploadData(file, {
					onProgress: onProgress
						? (e) => {
								onProgress(file.name, e.loadedBytes)
						  }
						: undefined,
				})

				if (onSuccess) onSuccess(file.name)
			} catch (e) {
				if (onError) onError(file.name, e as Error)
			}
		}

		return task()
	})

	await Promise.all(uploadTasks)
}

export const downloadFile = async (
	containerName: string,
	blobName: string
): Promise<File | undefined> => {
	const client = new BlobServiceClient(connString)

	const containerClient = client.getContainerClient(containerName)

	if (!(await containerClient.exists())) {
		await containerClient.create()
	}

	const blobClient = containerClient.getBlockBlobClient(blobName)

	const downloadResponse = await blobClient.download()

	const blob = await downloadResponse.blobBody

	if (!blob) return undefined

	return new File([blob], blobName)
}

interface FileDescriptor {
	name: string
	type?: string
}

export const listFiles = async (
	containerName: string
): Promise<FileDescriptor[]> => {
	const client = new BlobServiceClient(connString)

	const containerClient = client.getContainerClient(containerName)

	if (!(await containerClient.exists())) {
		await containerClient.create()
	}

	const result: FileDescriptor[] = []
	const iter = containerClient.listBlobsFlat()
	let blobItem = await iter.next()
	while (!blobItem.done) {
		result.push({
			name: blobItem.value.name,
			type: blobItem.value.properties.contentType,
		})
		blobItem = await iter.next()
	}

	return result
}

export const deleteFile = async (
	containerName: string,
	blobName: string
): Promise<void> => {
	const client = new BlobServiceClient(connString)
	const containerClient = client.getContainerClient(containerName)

	if (!(await containerClient.exists())) {
		return
	}

	const blobClient = containerClient.getBlockBlobClient(blobName)

	if (!(await blobClient.exists())) {
		return
	}

	await blobClient.delete({
		deleteSnapshots: 'include',
	})
}
