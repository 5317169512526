import { Footer as MantineFooter } from '@mantine/core'
import packagejson from '../../package.json'

export default function Footer() {
	return (
		<MantineFooter height={40} p={8}>
			Neoscreen - {packagejson.version}
		</MantineFooter>
	)
}
