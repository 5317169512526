import {
	Button,
	Grid,
	Group,
	Select,
	Textarea,
	TextInput,
	Text,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { compare, Operation } from 'fast-json-patch'
import { useTranslation } from 'react-i18next'
import { Calendar, FileUpload, Plus } from 'tabler-icons-react'
import UpdateExamDto, {
	UpdateExamSampleDto,
} from '../../../../models/api/exam/updateExamDto'
import Exam from '../../../../models/exam'
import { useEffect } from 'react'
import useUpdateExam from '../../../../hooks/useUpdateExam'
import useExtendableSelectOptions from '../../../../hooks/useExtendableSelectOptions'

interface SampleFormProps {
	exam: Exam
}

type FormData = UpdateExamSampleDto

const getInitialValuesFromExam = (e: Exam): FormData => ({
	bloodSampleOrigin: e.sample?.bloodSampleOrigin ?? '',
	oncologySampleOrigin: e.sample?.oncologySampleOrigin ?? '',
	sampleType: e.sample?.sampleType ?? '',
	samplePickupDate: e.sample?.samplePickupDate?.toISOString(),
	tests: e.sample?.tests ?? '',
	electronicPrescriptionCode: e.sample?.electronicPrescriptionCode ?? '',
	parafineCudeCode: e.sample?.parafineCudeCode ?? '',
	parafineCudeOrigin: e.sample?.parafineCudeOrigin ?? '',
})

export default function SampleForm(props: SampleFormProps) {
	const { exam } = props

	const { t, i18n } = useTranslation()
	const form = useForm<FormData>({
		initialValues: getInitialValuesFromExam(exam),
	})
	const updateMutation = useUpdateExam(exam)
	const [bloodOriginOptions, onCreateBloodOriginOption] =
		useExtendableSelectOptions(
			[
				'Neoscreen',
				t('examPage.examAdminTab.driver'),
				t('examPage.examAdminTab.courier'),
			],
			exam.sample.bloodSampleOrigin
		)
	const [oncologySampleOriginOptions, onCreateOncologySampleOriginOption] =
		useExtendableSelectOptions(
			[
				'Neoscreen',
				t('examPage.examAdminTab.driver'),
				t('examPage.examAdminTab.courier'),
			],
			exam.sample.oncologySampleOrigin
		)
	const [sampleTypeOptions, onCreateSampleTypeOption] =
		useExtendableSelectOptions(
			[
				t('examPage.examAdminTab.blood'),
				t('examPage.examAdminTab.saliva'),
				'DNA',
				'FFPE',
			],
			exam.sample.sampleType
		)

	useEffect(() => {
		form.setValues(getInitialValuesFromExam(exam))
	}, [exam])

	const getPatchOperations = (formValues: FormData): Operation[] => {
		const previous: UpdateExamDto = {
			sample: { ...getInitialValuesFromExam(exam) },
		}
		const next: UpdateExamDto = {
			sample: { ...formValues },
		}
		return compare(previous, next)
	}

	const handleSubmit = (formValues: FormData): void => {
		const patchOperations = getPatchOperations(formValues)
		updateMutation.mutate(patchOperations)
	}

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<Grid>
				<Grid.Col xs={12} md={6}>
					<Select
						label={t('examPage.examAdminTab.bloodSampleOrigin')}
						data={bloodOriginOptions}
						creatable
						getCreateLabel={(query) => (
							<Group>
								<Plus size={12} />
								<Text size='sm'>{query}</Text>
							</Group>
						)}
						onCreate={onCreateBloodOriginOption}
						nothingFound='Nothing found'
						searchable
						{...form.getInputProps('bloodSampleOrigin')}
					/>
				</Grid.Col>
				<Grid.Col xs={12} md={6}>
					<Select
						label={t('examPage.examAdminTab.oncologySampleOrigin')}
						data={oncologySampleOriginOptions}
						creatable
						getCreateLabel={(query) => (
							<Group>
								<Plus size={12} />
								<Text size='sm'>{query}</Text>
							</Group>
						)}
						onCreate={onCreateOncologySampleOriginOption}
						nothingFound='Nothing found'
						searchable
						{...form.getInputProps('oncologySampleOrigin')}
					/>
				</Grid.Col>
				<Grid.Col xs={12} md={6}>
					<Select
						label={t('examPage.examAdminTab.sampleType')}
						data={sampleTypeOptions}
						creatable
						getCreateLabel={(query) => (
							<Group>
								<Plus size={12} />
								<Text size='sm'>{query}</Text>
							</Group>
						)}
						onCreate={onCreateSampleTypeOption}
						nothingFound='Nothing found'
						searchable
						{...form.getInputProps('sampleType')}
					/>
				</Grid.Col>
				<Grid.Col xs={12} md={6}>
					<DatePicker
						locale={i18n.resolvedLanguage}
						label={t('examPage.examAdminTab.samplePickupDate')}
						placeholder={t('examPage.examAdminTab.pickDate')}
						icon={<Calendar size={16} />}
						error={form.getInputProps('samplePickupDate').error}
						value={
							form.getInputProps('samplePickupDate').value
								? new Date(
										form.getInputProps(
											'samplePickupDate'
										).value
								  )
								: null
						}
						onChange={(value) =>
							form
								.getInputProps('samplePickupDate')
								.onChange(value?.toISOString())
						}
					/>
				</Grid.Col>
				<Grid.Col span={12}>
					<Textarea
						label={t('examPage.examAdminTab.tests')}
						{...form.getInputProps('tests')}
					/>
				</Grid.Col>
				<Grid.Col span={12}>
					<TextInput
						label={t(
							'examPage.examAdminTab.electronicPrescriptionCode'
						)}
						{...form.getInputProps('electronicPrescriptionCode')}
					/>
				</Grid.Col>
				<Grid.Col xs={12} md={6}>
					<TextInput
						label={t('examPage.examAdminTab.parafineCudeCode')}
						{...form.getInputProps('parafineCudeCode')}
					/>
				</Grid.Col>
				<Grid.Col xs={12} md={6}>
					<TextInput
						label={t('examPage.examAdminTab.parafineCudeOrigin')}
						{...form.getInputProps('parafineCudeOrigin')}
					/>
				</Grid.Col>
				<Grid.Col>
					<Button
						variant='subtle'
						leftIcon={<FileUpload size={16} />}
					>
						{t('examPage.examAdminTab.uploadDoctorNote')}
					</Button>
				</Grid.Col>
				<Grid.Col>
					<Button
						type='submit'
						disabled={!getPatchOperations(form.values).length}
						loading={updateMutation.isLoading}
					>
						{t('update')}
					</Button>
				</Grid.Col>
			</Grid>
		</form>
	)
}
