import { ActionIcon, Group, Indicator, Paper } from '@mantine/core'
import dayjs from 'dayjs'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { InfoCircle, Trash } from 'tabler-icons-react'
import TypographyStack from '../../components/TypographyStack'
import useUser from '../../hooks/useUser'
import Exam from '../../models/exam'

interface ExamCardProps {
	exam: Exam
	onView: (id: string) => void
	onDelete: (exam: Exam) => void
}

export default memo(function ExamCard(props: ExamCardProps) {
	const { exam, onView, onDelete } = props

	const { t } = useTranslation()
	const user = useUser()

	const isNew = () =>
		Math.abs(dayjs(exam.createdAt).diff(dayjs(), 'seconds')) < 60 * 60 * 24

	if (!exam) return null

	return (
		<Indicator
			size={16}
			position='top-center'
			label='New'
			disabled={!isNew()}
		>
			<Paper radius='md' p='md' withBorder>
				<Group position='apart'>
					<TypographyStack
						title={exam.identifier ? exam.identifier : '-'}
						subtitle={t('identifier')}
					/>

					<TypographyStack
						title={exam.patient.name}
						subtitle={t('patientName')}
					/>

					<TypographyStack
						title={dayjs(exam.createdAt).format('lll')}
						subtitle={t('createdAt')}
					/>
					<Group>
						<ActionIcon
							size='lg'
							color='blue'
							onClick={() => onView(exam.id)}
						>
							<InfoCircle />
						</ActionIcon>
						{user?.isAdmin && (
							<ActionIcon
								size='lg'
								color='red'
								onClick={() => onDelete(exam)}
							>
								<Trash />
							</ActionIcon>
						)}
					</Group>
				</Group>
			</Paper>
		</Indicator>
	)
})
