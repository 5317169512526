import {
	Select,
	TextInput,
	Button,
	Loader,
	SelectItem,
	Stack,
	ActionIcon,
	Group,
	Box,
	MultiSelect,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { compare, Operation } from 'fast-json-patch'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { ArrowUpRightCircle } from 'tabler-icons-react'
import useUpdateExam from '../../../../hooks/useUpdateExam'
import useUser from '../../../../hooks/useUser'
import { examFromDto } from '../../../../mappers/examMappers'
import UpdateExamDto from '../../../../models/api/exam/updateExamDto'
import Exam from '../../../../models/exam'
import routes from '../../../../routes'
import ExamService from '../../../../services/examService'

interface GeneralFormProps {
	exam: Exam
}

type FormData = Pick<
	UpdateExamDto,
	'identifier' | 'fatherExamId' | 'motherExamId' | 'relatedExamIds'
>

export default function GeneralForm(props: GeneralFormProps) {
	const { exam } = props

	const navigate = useNavigate()
	const { t } = useTranslation()
	const user = useUser()

	const [fatherQuery, setFatherQuery] = useState<string>(
		exam.fatherExamId || ''
	)
	const [motherQuery, setMotherQuery] = useState<string>(
		exam.motherExamId || ''
	)
	const [relatedQuery, setRelatedQuery] = useState<string>('')

	const mapExamsToSelectItems = (exams: Exam[]): SelectItem[] => {
		return exams.map(mapExamToSelectItem)
	}

	const mapExamToSelectItem = (exam: Exam): SelectItem => {
		return {
			value: exam.id,
			label: `${exam.patient.name} - ${exam.identifier}`,
		}
	}

	const fatherSearchQuery = useQuery(
		['exams', 'search', 'father', fatherQuery],
		async () => {
			const response = await new ExamService(user?.accessToken).getAsync({
				searchTerm: fatherQuery,
				page: 0,
				pageSize: 50,
			})
			return mapExamsToSelectItems(
				response.data.map(examFromDto).filter((e) => e.identifier)
			)
		},
		{
			onError: () => {
				showNotification({
					color: 'red',
					message: t('fail'),
				})
			},
		}
	)

	const motherSearchQuery = useQuery(
		['exams', 'search', 'mother', motherQuery],
		async () => {
			const response = await new ExamService(user?.accessToken).getAsync({
				searchTerm: motherQuery,
				page: 0,
				pageSize: 50,
			})
			return mapExamsToSelectItems(
				response.data.map(examFromDto).filter((e) => e.identifier)
			)
		},
		{
			onError: () => {
				showNotification({
					color: 'red',
					message: t('fail'),
				})
			},
		}
	)

	const relatedSearchQuery = useQuery(
		['exams', 'search', 'related', relatedQuery],
		async () => {
			const response = await new ExamService(user?.accessToken).getAsync({
				searchTerm: relatedQuery,
				page: 0,
				pageSize: 50,
			})
			return mapExamsToSelectItems(
				response.data.map(examFromDto).filter((e) => e.identifier)
			)
		},
		{
			onError: () => {
				showNotification({
					color: 'red',
					message: t('fail'),
				})
			},
		}
	)

	const updateMutation = useUpdateExam(exam)

	const getInitialValuesFromExam = (e: Exam): FormData => ({
		identifier: e.identifier,
		fatherExamId: e.fatherExamId,
		motherExamId: e.motherExamId,
		relatedExamIds: e.relatedExamIds,
	})

	const form = useForm<FormData>({
		initialValues: getInitialValuesFromExam(exam),
	})

	useEffect(() => {
		form.setValues(getInitialValuesFromExam(exam))
	}, [exam])

	const getPatchOperations = (formValues: FormData): Operation[] =>
		compare(getInitialValuesFromExam(exam), formValues)

	const handleSubmit = (formValues: FormData): void => {
		const patchOperations = getPatchOperations(formValues)
		updateMutation.mutate(patchOperations)
	}

	return (
		<form onSubmit={form.onSubmit(handleSubmit)}>
			<Stack>
				<TextInput
					label={t('identifier')}
					{...form.getInputProps('identifier')}
				/>
				<Group
					sx={{
						alignItems: 'flex-end',
					}}
				>
					<Select
						label={t('examPage.fatherIdentifier')}
						{...form.getInputProps('fatherExamId')}
						data={
							fatherSearchQuery.isSuccess
								? fatherSearchQuery.data
								: []
						}
						searchable
						filter={() => true}
						clearable
						nothingFound={t('noResults')}
						onSearchChange={(query) => setFatherQuery(query)}
						rightSection={
							fatherSearchQuery.isFetching && <Loader size='xs' />
						}
						sx={{ flex: 1 }}
					/>
					<ActionIcon
						size='lg'
						variant='outline'
						color='blue'
						mb={1}
						disabled={!form.values.fatherExamId}
						onClick={() => {
							if (form.values.fatherExamId) {
								navigate(routes.exam(form.values.fatherExamId))
							}
						}}
					>
						<ArrowUpRightCircle />
					</ActionIcon>
				</Group>
				<Group
					sx={{
						alignItems: 'flex-end',
					}}
				>
					<Select
						label={t('examPage.motherIdentifier')}
						{...form.getInputProps('motherExamId')}
						data={
							motherSearchQuery.isSuccess
								? motherSearchQuery.data
								: []
						}
						searchable
						filter={() => true}
						clearable
						nothingFound={t('noResults')}
						onSearchChange={(query) => setMotherQuery(query)}
						rightSection={
							motherSearchQuery.isFetching && <Loader size='xs' />
						}
						sx={{ flex: 1 }}
					/>
					<ActionIcon
						size='lg'
						variant='outline'
						color='pink'
						mb={1}
						disabled={!form.values.motherExamId}
						onClick={() => {
							if (form.values.motherExamId) {
								navigate(routes.exam(form.values.motherExamId))
							}
						}}
					>
						<ArrowUpRightCircle />
					</ActionIcon>
				</Group>
				<MultiSelect
					label={t('examPage.relatedExams')}
					{...form.getInputProps('relatedExamIds')}
					data={
						relatedSearchQuery.isSuccess
							? relatedSearchQuery.data
							: []
					}
					searchable
					filter={() => true}
					clearable
					nothingFound={t('noResults')}
					onSearchChange={(query) => setRelatedQuery(query)}
					rightSection={
						relatedSearchQuery.isFetching && <Loader size='xs' />
					}
				/>
				<Box>
					<Button
						type='submit'
						disabled={!getPatchOperations(form.values).length}
						loading={updateMutation.isLoading}
					>
						{t('update')}
					</Button>
				</Box>
			</Stack>
		</form>
	)
}
