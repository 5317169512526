import { Center, Stack, Title, Loader, Container } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import EmptyState from '../../components/EmptyState'
import { examFromDto } from '../../mappers/examMappers'
import ExamService from '../../services/examService'
import PatientInfoForm from '../../components/PatientInfoForm'
import routes from '../../routes'

export default function PatientRegistration() {
	const { id: examId = '' } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const query = useQuery(['exam', examId], async () => {
		const dto = await new ExamService().getByIdAsync(examId)
		return examFromDto(dto)
	})

	if (query.isLoading) {
		return (
			<Center sx={{ height: '100%' }}>
				<Loader />
			</Center>
		)
	}

	if (!query.isSuccess) {
		return (
			<Center sx={{ height: '100%' }}>
				<EmptyState
					title={t('notFoundTitle')}
					message={t('patientPage.notFoundMessage')}
				/>
			</Center>
		)
	}

	return (
		<Container>
			<Stack>
				<Title>{t('patientPage.formTitle')}</Title>
				<PatientInfoForm
					exam={query.data}
					onSuccess={() => navigate(routes.sub.success)}
				/>
			</Stack>
		</Container>
	)
}
