import { Center } from '@mantine/core'
import { useEffect } from 'react'
import userManager from '../../configureUserManager'

export default function LoginSilentCallback() {
	useEffect(() => {
		async function init() {
			try {
				console.debug('Login silent callback triggered...')
				await userManager.signinSilentCallback()
				await userManager.clearStaleState()
			} catch (error) {
				console.log(error)
			}
		}

		init()
	}, [])

	return (
		<Center sx={{ height: '100vh' }}>
			<p>Login callback...</p>
		</Center>
	)
}
