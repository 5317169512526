import { Center } from '@mantine/core'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import userManager from '../../configureUserManager'
import routes from '../../routes'

export default function LogoutCallback() {
	const navigate = useNavigate()

	useEffect(() => {
		const init = async () => {
			try {
				await userManager.signoutRedirectCallback()
			} catch (e) {
				console.log(e)
			} finally {
				navigate(routes.root)
			}
		}

		init()
	}, [])

	return (
		<Center sx={{ height: '100vh' }}>
			<p>You have logged out succesfully.</p>
		</Center>
	)
}
