import { showNotification } from '@mantine/notifications'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { examFromDto } from '../mappers/examMappers'
import PagedResponse from '../models/api/pagedResponse'
import Exam from '../models/exam'
import ExamService from '../services/examService'
import useUser from './useUser'

const useExams = (page: number, pageSize: number, searchTerm: string) => {
	const user = useUser()
	const { t } = useTranslation()

	return useQuery(
		['exams', page, searchTerm],
		async () => {
			const response = await new ExamService(user?.accessToken).getAsync({
				searchTerm: searchTerm,
				page: page - 1,
				pageSize,
			})
			const result: PagedResponse<Exam[]> = {
				data: response.data.map(examFromDto),
				page: response.page + 1,
				pageSize: response.pageSize,
				total: response.total,
			}
			return result
		},
		{
			keepPreviousData: true,
			retry: false,
			onError: () => {
				showNotification({
					message: t('fail'),
					color: 'red',
				})
			},
		}
	)
}

export default useExams
