import { Modal } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import PatientInfoForm from '../../components/PatientInfoForm'
interface CreateExamModalProps {
	open: boolean
	onClose: () => void
}

export default function CreateExamModal(props: CreateExamModalProps) {
	const { open, onClose } = props

	const { t } = useTranslation()
	const queryClient = useQueryClient()

	const close = () => onClose()

	return (
		<Modal
			opened={open}
			onClose={close}
			centered
			size='xl'
			title={t('createExamModal.title')}
			overflow='inside'
		>
			<PatientInfoForm
				onSuccess={() => {
					close()
					queryClient.invalidateQueries(['exams'])
				}}
			/>
		</Modal>
	)
}
