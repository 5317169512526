import {
	Button,
	Checkbox,
	Divider,
	Group,
	Modal,
	SimpleGrid,
	Stack,
	Textarea,
} from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import TypographyStack from '../../../../components/TypographyStack'
import { getGenomeCoords } from '../../../../helpers/findingHelpers'
import useUser from '../../../../hooks/useUser'
import { examFromDto } from '../../../../mappers/examMappers'
import UpdateFindingDto from '../../../../models/api/exam/updateFindingDto'
import Finding from '../../../../models/finding'
import ExamService from '../../../../services/examService'

interface FindingCardDetailsProps {
	open: boolean
	onClose: () => void
	examId: string
	finding: Finding
}

export default function FindingCardDetails(props: FindingCardDetailsProps) {
	const { open, onClose, finding, examId } = props

	const { t } = useTranslation()
	const user = useUser()

	const queryClient = useQueryClient()

	const updateMutation = useMutation(
		async (request: UpdateFindingDto) => {
			const dto = await new ExamService(
				user?.accessToken
			).updateFindingAsync(examId, finding.externalId, request)
			return examFromDto(dto)
		},
		{
			onSuccess: async () => {
				await queryClient.invalidateQueries(['exams', examId], {
					exact: true,
				})
				showNotification({
					color: 'green',
					message: t('updateSucceeded'),
				})
				onClose()
			},
			onError: () => {
				showNotification({
					color: 'red',
					message: t('updateFailed'),
				})
			},
		}
	)

	const handleSubmit = (formData: UpdateFindingDto) => {
		updateMutation.mutate({
			notes: formData.notes,
			doctorApproved: formData.doctorApproved,
			doctorApprovedAt: formData.doctorApprovedAt,
		})
	}

	const form = useForm<UpdateFindingDto>({
		initialValues: {
			notes: finding.notes,
			doctorApproved: finding.doctorApproved,
			doctorApprovedAt: finding.doctorApprovedAt,
		},
	})

	return (
		<Modal
			opened={open}
			onClose={onClose}
			size='xl'
			padding='xl'
			centered
			title={`${finding.genomicData.geneName} - ${getGenomeCoords(
				finding.genomicData
			)}`}
		>
			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Stack>
					<Divider />
					<SimpleGrid cols={3}>
						<TypographyStack
							title={finding.genomicData.hgvsC ?? '-'}
							subtitle='HGVS C'
						/>
						<TypographyStack
							title={finding.genomicData.hgvsP ?? '-'}
							subtitle='HGVS P'
						/>
						<TypographyStack
							title={
								finding.genomicData.aggregateInterpretation ??
								'-'
							}
							subtitle='Clinvar Aggregate Interpretation'
						/>
						<TypographyStack
							title={
								finding.genomicData.autoClassification ?? '-'
							}
							subtitle='ACMG Auto Interpretation'
						/>
						<TypographyStack
							title={finding.genomicData.identifier ?? '-'}
							subtitle='DBSNP Identifier'
						/>
						<TypographyStack
							title={
								finding.genomicData.gnomadExomeAlleleFrequency?.toString() ??
								'-'
							}
							subtitle='GNOMAD Exome Allele Frequency'
						/>
						<TypographyStack
							title={
								finding.genomicData.gnomadGenomeAlleleFrequency?.toString() ??
								'-'
							}
							subtitle='GNOMAD Genome Allele Frequency'
						/>
						<TypographyStack
							title={
								finding.genomicData.variantAlleleFrequency?.join(
									', '
								) ?? '-'
							}
							subtitle='Variant Allele Frequency'
						/>
						<TypographyStack
							title={finding.genomicData.revel?.toString() ?? '-'}
							subtitle='Revel'
						/>
						<TypographyStack
							title={
								finding.genomicData.phredScore?.toString() ??
								'-'
							}
							subtitle='CADD Score'
						/>
						<TypographyStack
							title={finding.genomicData.inheritance ?? '-'}
							subtitle='Inheritance'
						/>
						<TypographyStack
							title={finding.genomicData.disorders ?? '-'}
							subtitle='Disorders'
						/>
						<TypographyStack
							title={finding.genomicData.sequenceOntology ?? '-'}
							subtitle='Sequence Ontology'
						/>
					</SimpleGrid>
					<Group grow>
						<Button
							variant='outline'
							compact
							onClick={() => {
								window.open(
									`https://www.ensembl.org/Homo_sapiens/Variation/Explore?v=${finding.genomicData.identifier}`,
									'_blank'
								)
							}}
						>
							ENSEMBL
						</Button>
						<Button
							variant='outline'
							compact
							onClick={() => {
								const resolveSequenceValue = (
									sequence: string
								) => (sequence === '-' ? '' : sequence)

								const refSequence = resolveSequenceValue(
									finding.genomicData.alteration.split('/')[0]
								)

								const altSequence = resolveSequenceValue(
									finding.genomicData.alteration.split('/')[1]
								)
								window.open(
									`https://varsome.com/variant/hg38/${
										finding.genomicData.chromosome
									}-${
										finding.genomicData.position
									}-${refSequence}-${
										altSequence === '-' ? '' : altSequence
									}?annotation-mode=germline`,
									'_blank'
								)
							}}
						>
							Varsome
						</Button>
						<Button
							variant='outline'
							compact
							onClick={() => {
								window.open(
									`https://www.ncbi.nlm.nih.gov/snp/${finding.genomicData.identifier}`,
									'_blank'
								)
							}}
						>
							Ncbi
						</Button>
						<Button
							variant='outline'
							compact
							onClick={() => {
								window.open(
									`https://gnomad.broadinstitute.org/variant/${finding.genomicData.identifier}?dataset=gnomad_r3`,
									'_blank'
								)
							}}
						>
							GNOMAD
						</Button>
						<Button
							variant='outline'
							compact
							disabled={!finding.genomicData.omimId}
							onClick={() => {
								window.open(
									`https://www.omim.org/entry/${finding.genomicData.omimId}`,
									'_blank'
								)
							}}
						>
							OMIM
						</Button>
						<Button
							variant='outline'
							compact
							disabled={!finding.genomicData.mastermind}
							onClick={() => {
								if (!finding.genomicData.mastermind) return
								window.open(
									finding.genomicData.mastermind,
									'_blank'
								)
							}}
						>
							Mastermind
						</Button>
					</Group>
					<Divider />
					<Group grow>
						<Checkbox
							label={t('examPage.examAnalysisTab.doctorApproved')}
							{...form.getInputProps('doctorApproved', {
								type: 'checkbox',
							})}
							onChange={(e) => {
								form.setFieldValue(
									'doctorApproved',
									e.target.checked
								)
								form.setFieldValue(
									'doctorApprovedAt',
									e.target.checked ? new Date() : undefined
								)
							}}
						/>
						<DatePicker
							variant='filled'
							{...form.getInputProps('doctorApprovedAt')}
							placeholder={t(
								'examPage.examAnalysisTab.doctorApprovedAt'
							)}
							disabled
						/>
					</Group>
					<Textarea
						label={t('examPage.notes')}
						{...form.getInputProps('notes')}
					/>
					<Group>
						<Button
							type='submit'
							loading={updateMutation.isLoading}
						>
							{t('save')}
						</Button>
						<Button
							variant='subtle'
							onClick={() => onClose()}
							disabled={updateMutation.isLoading}
						>
							{t('cancel')}
						</Button>
					</Group>
				</Stack>
			</form>
		</Modal>
	)
}
