import { Container, Stack, Title } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { pinSuccessStorageKey } from '../../constants'
import routes from '../../routes'
import PatientInfoForm from '../../components/PatientInfoForm'

export default function PatientSelfRegistration() {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [pinSuccess] = useLocalStorage({
		key: pinSuccessStorageKey,
	})

	useEffect(() => {
		if (!pinSuccess) {
			navigate(routes.patientPin)
		}
	}, [pinSuccess])

	if (!pinSuccess) return null

	return (
		<Container size='xs'>
			<Stack>
				<Title>{t('patientPage.formTitle')}</Title>
				<PatientInfoForm
					onSuccess={() => navigate(routes.sub.success)}
				/>
			</Stack>
		</Container>
	)
}
