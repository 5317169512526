import Oidc, { UserManager } from 'oidc-client'
import OidcConfig from './oidcConfig'
import queryClient from './queryClient'
import { setApiAuthToken } from './services/apiClient'

Oidc.Log.logger = console

const userManager: UserManager = new UserManager(OidcConfig)

userManager.events.addUserSessionChanged(async () => {
	const user = await userManager.getUser()
	queryClient.setQueryData('user', user)
	setApiAuthToken(user?.access_token ?? '')
})

userManager.events.addUserLoaded(async (user) => {
	console.log('User loaded...')
	queryClient.setQueryData('user', user)
})

userManager.events.addAccessTokenExpiring(() => {
	console.log('Token is expiring...')
})

userManager.events.addAccessTokenExpired(async () => {
	console.log('Token has expired...')
	userManager.signinSilent()
})

userManager.events.addUserSignedIn(async () => {
	console.log('User signed in')
	const user = await userManager.getUser()
	queryClient.setQueryData('user', user)
})

userManager.events.addUserSignedOut(async () => {
	console.log('User signed out')
	await userManager.removeUser()
	await userManager.clearStaleState()
	queryClient.setQueryData('user', null)
})

userManager.events.addSilentRenewError(async () => {
	console.log('Silent renew failed...')
})

export default userManager
