import { Text, Alert, Stack } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useTranslation } from 'react-i18next'
import PatientInfoForm from '../../../../components/PatientInfoForm'
import { PatientCommunicationProcessingStatus } from '../../../../models/api/exam/patientCommunicationDto'
import Exam from '../../../../models/exam'

interface PatientFormProps {
	exam: Exam
}

export default function PatientForm(props: PatientFormProps) {
	const { exam } = props

	const { t } = useTranslation()

	return (
		<Stack>
			{exam.patientCommunication && (
				<Alert>
					{exam.patientCommunication.processingStatus ===
						PatientCommunicationProcessingStatus.Pending && (
						<Text>Patient has been notified.</Text>
					)}
					{exam.patientCommunication.processingStatus ===
						PatientCommunicationProcessingStatus.Failed && (
						<Text>Patient communication failed.</Text>
					)}
					{exam.patientCommunication.processingStatus ===
						PatientCommunicationProcessingStatus.Completed && (
						<Text>
							Patient has been notified and has completed the
							registration.
						</Text>
					)}
				</Alert>
			)}
			<PatientInfoForm
				exam={exam}
				onSuccess={() =>
					showNotification({
						message: t('success'),
						color: 'green',
					})
				}
			/>
		</Stack>
	)
}
