import {
	Paper,
	Button,
	Group,
	Box,
	Tooltip,
	Stack,
	SimpleGrid,
} from '@mantine/core'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checks, Pencil } from 'tabler-icons-react'
import TypographyStack from '../../../../components/TypographyStack'
import { getGenomeCoords } from '../../../../helpers/findingHelpers'
import Finding from '../../../../models/finding'
import FindingCardDetails from './FindingCardDetails'

interface FindingCardProps {
	examId: string
	finding: Finding
}

export default function FindingCard(props: FindingCardProps) {
	const { examId, finding } = props
	const { t } = useTranslation()
	const [detailsOpen, setDetailsOpen] = useState<boolean>(false)

	const getBadges = () => {
		if (finding.notes || finding.doctorApproved) {
			return (
				<Group>
					{finding.doctorApproved && (
						<Tooltip
							label={t(
								'examPage.examAnalysisTab.doctorApprovedAtDate',
								{
									date: dayjs(
										finding.doctorApprovedAt
									).format('DD/MM/YYYY'),
								}
							)}
						>
							<Box
								sx={(theme) => ({
									color: theme.colors.blue,
								})}
							>
								<Checks size={16} />
							</Box>
						</Tooltip>
					)}
					{finding.notes && (
						<Tooltip label={finding.notes}>
							<Box
								sx={(theme) => ({
									color: theme.colors.blue,
								})}
							>
								<Pencil size={16} />
							</Box>
						</Tooltip>
					)}
				</Group>
			)
		}

		return null
	}

	return (
		<>
			<FindingCardDetails
				open={detailsOpen}
				onClose={() => setDetailsOpen(false)}
				finding={finding}
				examId={examId}
			/>
			<Paper withBorder p='lg' sx={{ width: 350 }}>
				<Stack>
					<Group position='apart'>
						<TypographyStack
							title={finding.genomicData.geneName ?? '-'}
							subtitle={getGenomeCoords(finding.genomicData)}
						/>
						{getBadges()}
					</Group>
					<SimpleGrid cols={2}>
						<TypographyStack
							title={finding.genomicData.hgvsC ?? '-'}
							subtitle='HGVS C'
						/>
						<TypographyStack
							title={finding.genomicData.hgvsP ?? '-'}
							subtitle='HGVS P'
						/>
					</SimpleGrid>
					<Button
						compact
						variant='light'
						onClick={() => setDetailsOpen(true)}
					>
						{t('more')}
					</Button>
				</Stack>
			</Paper>
		</>
	)
}
