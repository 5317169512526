import { useQuery } from 'react-query'
import { examFromDto } from '../mappers/examMappers'
import ExamService from '../services/examService'
import useUser from './useUser'

const useExam = (examId: string) => {
	const user = useUser()

	return useQuery(
		['exams', examId],
		async () => {
			const dto = await new ExamService(user?.accessToken).getByIdAsync(
				examId
			)
			return examFromDto(dto)
		},
		{
			enabled: !!examId,
		}
	)
}

export default useExam
