import { Badge, Box, Button, Group, Menu, Stack, Text } from '@mantine/core'
import { Dropzone } from '@mantine/dropzone'
import fileDownload from 'js-file-download'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Check,
	CloudUpload,
	Download,
	File,
	Trash,
	X,
} from 'tabler-icons-react'
import useBlobStorage from '../hooks/useBlobStorage'
import {
	deleteFile,
	downloadFile,
	uploadFiles,
} from '../services/azureBlobService'

export type UploadFunction = (examId: string) => Promise<void>

interface FileManagerProps {
	examId?: string
	uploadRef?: React.MutableRefObject<UploadFunction | undefined>
}

interface UploadState {
	file: File
	progress: number
	status: 'pending' | 'inprogress' | 'success' | 'error' | 'downloading'
}

export default function FileManager(props: FileManagerProps) {
	const { examId, uploadRef } = props

	const { t } = useTranslation()
	const filesQuery = useBlobStorage(examId)

	const [uploadStates, setUploadStates] = useState<Map<string, UploadState>>(
		new Map<string, UploadState>()
	)

	useEffect(() => {
		if (uploadRef) uploadRef.current = onUploadFiles
	}, [uploadStates, uploadRef])

	const isDownloading = (): boolean =>
		[...uploadStates.values()].some((s) => s.status === 'inprogress')

	const onUploadFiles = async (examId: string): Promise<void> => {
		if (!uploadStates.size || !examId) return

		await uploadFiles(
			examId,
			[...uploadStates.values()].map((s) => s.file),
			(fileName) =>
				setUploadStates((prev) => {
					const newStates = new Map<string, UploadState>(prev)

					const uploadState = newStates.get(fileName)

					if (uploadState) uploadState.status = 'inprogress'

					return newStates
				}),
			(fileName, uploadedBytes) =>
				setUploadStates((prev) => {
					const newStates = new Map<string, UploadState>(prev)

					const uploadState = newStates.get(fileName)

					if (uploadState)
						uploadState.progress =
							(uploadedBytes / uploadState.file.size) * 100

					return newStates
				}),
			(fileName) => {
				setUploadStates((prev) => {
					const newStates = new Map<string, UploadState>(prev)

					const uploadState = newStates.get(fileName)

					if (uploadState) uploadState.status = 'success'

					return newStates
				})
			},
			(fileName) =>
				setUploadStates((prev) => {
					const newStates = new Map<string, UploadState>(prev)

					const uploadState = newStates.get(fileName)

					if (uploadState) uploadState.status = 'error'

					return newStates
				})
		)

		setUploadStates(new Map())
		filesQuery.refetch()
	}

	const onDownloadFile = async (fileName: string) => {
		setUploadStates((prev) => {
			const newStates = new Map<string, UploadState>(prev)

			const uploadState = newStates.get(fileName)

			if (uploadState) uploadState.status = 'downloading'

			return newStates
		})
		const file = await downloadFile(examId ?? '', fileName)

		if (!file) return

		fileDownload(file, file?.name, file.type)

		setUploadStates((prev) => {
			const newStates = new Map<string, UploadState>(prev)

			const uploadState = newStates.get(fileName)

			if (uploadState) uploadState.status = 'success'

			return newStates
		})
	}

	return (
		<Stack>
			<Dropzone
				onDrop={(files) => {
					const newState = new Map<string, UploadState>()
					files.forEach((file) => {
						newState.set(file.name, {
							file,
							progress: 0,
							status: 'pending',
						})
					})
					setUploadStates(newState)
				}}
			>
				{() => (
					<Stack align='center'>
						<Group>
							<File color='gray' />
							<Text color='dimmed'>{t('dropzoneText')}</Text>
						</Group>

						{!!uploadStates.size && (
							<Group>
								{[...uploadStates.values()].map(
									(uploadState) => (
										<Badge
											key={uploadState.file.name}
											rightSection={
												<>
													{uploadState.status ===
														'success' && (
														<Check
															size={16}
															color='green'
														/>
													)}
													{uploadState.status ===
														'inprogress' && (
														<Text size='xs'>{`${Math.round(
															uploadState.progress
														)}%`}</Text>
													)}
													{uploadState.status ===
														'error' && (
														<X
															size={16}
															color='red'
														/>
													)}
												</>
											}
										>
											{uploadState.file.name}
										</Badge>
									)
								)}
							</Group>
						)}
					</Stack>
				)}
			</Dropzone>

			<Group>
				{!uploadRef && !!examId && (
					<Button
						disabled={!uploadStates.size}
						loading={isDownloading()}
						onClick={() => onUploadFiles(examId)}
						leftIcon={<CloudUpload size={16} />}
					>
						{t('upload')}
					</Button>
				)}

				<Button
					disabled={!uploadStates.size || isDownloading()}
					variant='subtle'
					onClick={() => setUploadStates(new Map())}
				>
					{t('clear')}
				</Button>
			</Group>

			{!!examId && filesQuery.isSuccess && (
				<Box
					sx={{
						display: 'flex',
						gap: 5,
						flexWrap: 'wrap',
						justifyContent: 'flex-start',
					}}
				>
					{filesQuery.data.map((x) => (
						<Menu
							key={x.name}
							trigger='hover'
							control={<Badge>{x.name}</Badge>}
						>
							<Menu.Item
								icon={<Download size={14} />}
								color='blue'
								onClick={() => onDownloadFile(x.name)}
							>
								{t('download')}
							</Menu.Item>
							<Menu.Item
								icon={<Trash size={14} />}
								color='red'
								onClick={() => {
									deleteFile(examId, x.name)
									filesQuery.refetch()
								}}
							>
								{t('delete')}
							</Menu.Item>
						</Menu>
					))}
				</Box>
			)}
		</Stack>
	)
}
